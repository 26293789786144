import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, normalizeClass as _normalizeClass, vModelText as _vModelText, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "danmu-pan" }
const _hoisted_2 = { class: "controller" }
const _hoisted_3 = { class: "loop" }
const _hoisted_4 = { class: "ss-switch" }
const _hoisted_5 = { class: "ss-range" }
const _hoisted_6 = { class: "controller input" }
const _hoisted_7 = ["onMouseenter", "onTouchstart", "data-id"]
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "name" }
const _hoisted_10 = { class: "info" }
const _hoisted_11 = { class: "time" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_vue_danmaku = _resolveComponent("vue-danmaku")!

  return (_openBlock(), _createElementBlock("div", {
    id: "chat-pan",
    class: _normalizeClass('chat-pan' + (_ctx.runtimeData.tags.openSideBar ? ' open': '') + (_ctx.runtimeData.sysConfig.opt_no_window ? ' withBar': ''))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_vue_danmaku, {
        style: {"height":"calc(100vh - 40px)","width":"100%"},
        ref: "danmakuRef",
        channels: 0,
        danmus: _ctx.danmus,
        speeds: _ctx.opt.speeds,
        randomChannel: "",
        top: 2,
        loop: _ctx.opt.loop,
        useSlot: ""
      }, {
        dm: _withCtx(({ index, danmu }) => [
          _createElementVNode("div", {
            onMouseenter: ($event: any) => (_ctx.pause(index)),
            onMouseleave: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.play && _ctx.play(...args))),
            onTouchstart: ($event: any) => (_ctx.pause(index)),
            onTouchend: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.play && _ctx.play(...args))),
            "data-id": index,
            class: _normalizeClass('danmu' + ( index == 0 ? ' new' : '' )
                            + ( _ctx.runtimeData.loginInfo.uin == danmu.id ? ' me' : '' )
                            + ( (_ctx.parseIndex != index && _ctx.parseIndex != -1) ? ' opacity' : '' ))
          }, [
            _createElementVNode("img", {
              name: "avatar",
              src: 'https://q1.qlogo.cn/g?b=qq&s=0&nk=' + danmu.id
            }, null, 8, _hoisted_8),
            _createElementVNode("a", null, _toDisplayString(_ctx.danmus.length - index + 1), 1),
            _createElementVNode("span", null, _toDisplayString(danmu.text), 1)
          ], 42, _hoisted_7)
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", {
              class: "back",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openLeftBar && _ctx.openLeftBar(...args)))
            }, [
              _createVNode(_component_font_awesome_icon, { icon: ['fas', 'angle-left'] })
            ]),
            _createElementVNode("div", {
              class: "back",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.opera && _ctx.opera(...args)))
            }, [
              (_ctx.parseIndex == -1)
                ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                    key: 0,
                    icon: ['fas', 'pause']
                  }))
                : (_openBlock(), _createBlock(_component_font_awesome_icon, {
                    key: 1,
                    icon: ['fas', 'play']
                  }))
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_font_awesome_icon, { icon: ['fas', 'arrows-rotate'] }),
              _createElementVNode("label", _hoisted_4, [
                _withDirectives(_createElementVNode("input", {
                  type: "checkbox",
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.opt.loop) = $event)),
                  checked: ""
                }, null, 512), [
                  [_vModelCheckbox, _ctx.opt.loop]
                ]),
                _cache[9] || (_cache[9] = _createElementVNode("div", null, [
                  _createElementVNode("div")
                ], -1))
              ])
            ]),
            _cache[10] || (_cache[10] = _createElementVNode("div", { class: "space" }, null, -1)),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_font_awesome_icon, {
                class: _normalizeClass(_ctx.opt.speeds < 120 ? 'w' : '' ),
                icon: ['fas', 'gauge-high']
              }, null, 8, ["class"]),
              _withDirectives(_createElementVNode("input", {
                style: _normalizeStyle(`background-size: ${_ctx.opt.speeds / 8}% 100%;`),
                type: "range",
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.opt.speeds) = $event)),
                min: "20",
                max: "800",
                step: "20"
              }, null, 4), [
                [_vModelText, _ctx.opt.speeds]
              ]),
              _createElementVNode("span", {
                style: _normalizeStyle(`color: var(--color-font${_ctx.opt.speeds / 8 > 50 ? '-r' : ''})`)
              }, _toDisplayString(_ctx.opt.speeds) + " px/s", 5)
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _withDirectives(_createElementVNode("input", {
              onKeyup: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.sendMsg && _ctx.sendMsg(...args))),
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.msg) = $event)),
              onPaste: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.addImg && _ctx.addImg(...args))),
              id: "msgInput",
              class: "msgInput"
            }, null, 544), [
              [_vModelText, _ctx.msg]
            ])
          ]),
          _createElementVNode("div", {
            class: _normalizeClass('danmu-bg' + (_ctx.parseIndex != -1 ? ' hidden' : ''))
          }, [
            _cache[11] || (_cache[11] = _createElementVNode("svg", {
              class: "bg",
              width: "930",
              height: "414",
              viewBox: "0 0 930 414",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, [
              _createElementVNode("g", { "clip-path": "url(#clip0_810_16)" }, [
                _createElementVNode("rect", {
                  width: "1.40442",
                  height: "49",
                  rx: "0.70221",
                  transform: "matrix(-0.745625 -0.666366 -0.666366 0.745625 455.05 67.6182)",
                  fill: "var(--color-main)"
                }),
                _createElementVNode("path", {
                  d: "M402.229 113.088L407.629 103L413.029 113.088L421 117.867L413.029 122.646L407.629 133L403.514 122.646L394 117.867L402.229 113.088Z",
                  stroke: "var(--color-main)",
                  "stroke-linecap": "round"
                }),
                _createElementVNode("rect", {
                  width: "1.40442",
                  height: "49",
                  rx: "0.70221",
                  transform: "matrix(-0.745625 -0.666366 -0.666366 0.745625 516.05 146.936)",
                  fill: "var(--color-main)"
                }),
                _createElementVNode("path", {
                  d: "M463.229 192.406L468.629 182.318L474.029 192.406L482 197.185L474.029 201.964L468.629 212.318L464.514 201.964L455 197.185L463.229 192.406Z",
                  stroke: "var(--color-main)",
                  "stroke-linecap": "round"
                }),
                _createElementVNode("rect", {
                  width: "1.40442",
                  height: "49",
                  rx: "0.70221",
                  transform: "matrix(-0.745625 -0.666366 -0.666366 0.745625 330.05 197.936)",
                  fill: "var(--color-main)"
                }),
                _createElementVNode("path", {
                  d: "M277.229 243.406L282.629 233.318L288.029 243.406L296 248.185L288.029 252.964L282.629 263.318L278.514 252.964L269 248.185L277.229 243.406Z",
                  stroke: "var(--color-main)",
                  "stroke-linecap": "round"
                }),
                _createElementVNode("rect", {
                  width: "1.40442",
                  height: "49",
                  rx: "0.70221",
                  transform: "matrix(-0.745625 -0.666366 -0.666366 0.745625 468.05 290.936)",
                  fill: "var(--color-main)"
                }),
                _createElementVNode("path", {
                  d: "M415.229 336.406L420.629 326.318L426.029 336.406L434 341.185L426.029 345.964L420.629 356.318L416.514 345.964L407 341.185L415.229 336.406Z",
                  stroke: "var(--color-main)",
                  "stroke-linecap": "round"
                }),
                _createElementVNode("rect", {
                  width: "1.40442",
                  height: "49",
                  rx: "0.70221",
                  transform: "matrix(-0.745625 -0.666366 -0.666366 0.745625 656.05 224.936)",
                  fill: "var(--color-main)"
                }),
                _createElementVNode("path", {
                  d: "M603.229 270.406L608.629 260.318L614.029 270.406L622 275.185L614.029 279.964L608.629 290.318L604.514 279.964L595 275.185L603.229 270.406Z",
                  stroke: "var(--color-main)",
                  "stroke-linecap": "round"
                }),
                _createElementVNode("rect", {
                  width: "1.40442",
                  height: "49",
                  rx: "0.70221",
                  transform: "matrix(-0.745625 -0.666366 -0.666366 0.745625 803.05 70.9359)",
                  fill: "var(--color-main)"
                }),
                _createElementVNode("path", {
                  d: "M750.229 116.406L755.629 106.318L761.029 116.406L769 121.185L761.029 125.964L755.629 136.318L751.514 125.964L742 121.185L750.229 116.406Z",
                  stroke: "var(--color-main)",
                  "stroke-linecap": "round"
                }),
                _createElementVNode("rect", {
                  width: "1.40442",
                  height: "49",
                  rx: "0.70221",
                  transform: "matrix(-0.745625 -0.666366 -0.666366 0.745625 864.05 276.936)",
                  fill: "var(--color-main)"
                }),
                _createElementVNode("path", {
                  d: "M811.229 322.406L816.629 312.318L822.029 322.406L830 327.185L822.029 331.964L816.629 342.318L812.514 331.964L803 327.185L811.229 322.406Z",
                  stroke: "var(--color-main)",
                  "stroke-linecap": "round"
                }),
                _createElementVNode("rect", {
                  width: "1.40442",
                  height: "49",
                  rx: "0.70221",
                  transform: "matrix(-0.745625 -0.666366 -0.666366 0.745625 144.05 183.936)",
                  fill: "var(--color-main)"
                }),
                _createElementVNode("path", {
                  d: "M91.2286 229.406L96.6286 219.318L102.029 229.406L110 234.185L102.029 238.964L96.6286 249.318L92.5143 238.964L83 234.185L91.2286 229.406Z",
                  stroke: "var(--color-main)",
                  "stroke-linecap": "round"
                }),
                _createElementVNode("rect", {
                  width: "1.40442",
                  height: "49",
                  rx: "0.70221",
                  transform: "matrix(-0.745625 -0.666366 -0.666366 0.745625 171.05 34.9359)",
                  fill: "var(--color-main)"
                }),
                _createElementVNode("rect", {
                  width: "1.40442",
                  height: "49",
                  rx: "0.70221",
                  transform: "matrix(-0.745625 -0.666366 -0.666366 0.745625 256.699 175.936)",
                  fill: "var(--color-main)"
                }),
                _createElementVNode("rect", {
                  width: "1.40442",
                  height: "49",
                  rx: "0.70221",
                  transform: "matrix(-0.745625 -0.666366 -0.666366 0.745625 701.699 132.936)",
                  fill: "var(--color-main)"
                }),
                _createElementVNode("rect", {
                  width: "1.40442",
                  height: "92.07",
                  rx: "0.70221",
                  transform: "matrix(-0.745625 -0.666366 -0.666366 0.745625 302.699 16.9359)",
                  fill: "var(--color-main)"
                }),
                _createElementVNode("rect", {
                  width: "1.40442",
                  height: "92.07",
                  rx: "0.70221",
                  transform: "matrix(-0.745625 -0.666366 -0.666366 0.745625 344.4 326.936)",
                  fill: "var(--color-main)"
                }),
                _createElementVNode("rect", {
                  width: "1.40442",
                  height: "92.07",
                  rx: "0.70221",
                  transform: "matrix(-0.745625 -0.666366 -0.666366 0.745625 657.4 337.936)",
                  fill: "var(--color-main)"
                }),
                _createElementVNode("rect", {
                  width: "1.40442",
                  height: "92.07",
                  rx: "0.70221",
                  transform: "matrix(-0.745625 -0.666366 -0.666366 0.745625 847.4 167.936)",
                  fill: "var(--color-main)"
                }),
                _createElementVNode("path", {
                  d: "M118.229 80.4062L123.629 70.3177L129.029 80.4062L137 85.185L129.029 89.9638L123.629 100.318L119.514 89.9638L110 85.185L118.229 80.4062Z",
                  stroke: "var(--color-main)",
                  "stroke-linecap": "round"
                }),
                _createElementVNode("rect", {
                  width: "1.40442",
                  height: "49",
                  rx: "0.70221",
                  transform: "matrix(-0.745625 -0.666366 -0.666366 0.745625 205.05 372.936)",
                  fill: "var(--color-main)"
                }),
                _createElementVNode("path", {
                  d: "M152.229 418.406L157.629 408.318L163.029 418.406L171 423.185L163.029 427.964L157.629 438.318L153.514 427.964L144 423.185L152.229 418.406Z",
                  stroke: "var(--color-main)",
                  "stroke-linecap": "round"
                }),
                _createElementVNode("rect", {
                  width: "1.40442",
                  height: "49",
                  rx: "0.70221",
                  transform: "matrix(-0.745625 -0.666366 -0.666366 0.745625 756.05 408.936)",
                  fill: "var(--color-main)"
                }),
                _createElementVNode("rect", {
                  width: "1.40442",
                  height: "49",
                  rx: "0.70221",
                  transform: "matrix(-0.745625 -0.666366 -0.666366 0.745625 686.05 5.93591)",
                  fill: "var(--color-main)"
                }),
                _createElementVNode("path", {
                  d: "M633.229 51.4062L638.629 41.3177L644.029 51.4062L652 56.185L644.029 60.9638L638.629 71.3177L634.514 60.9638L625 56.185L633.229 51.4062Z",
                  stroke: "var(--color-main)",
                  "stroke-linecap": "round"
                }),
                _createElementVNode("circle", {
                  cx: "291.5",
                  cy: "122.5",
                  r: "13",
                  stroke: "var(--color-main)",
                  "stroke-linecap": "round"
                }),
                _createElementVNode("circle", {
                  cx: "846.5",
                  cy: "407.5",
                  r: "13",
                  stroke: "var(--color-main)",
                  "stroke-linecap": "round"
                }),
                _createElementVNode("circle", {
                  cx: "910.5",
                  cy: "64.5",
                  r: "13",
                  stroke: "var(--color-main)",
                  "stroke-linecap": "round"
                })
              ]),
              _createElementVNode("defs", null, [
                _createElementVNode("clipPath", { id: "clip0_810_16" }, [
                  _createElementVNode("rect", {
                    width: "930",
                    height: "414",
                    rx: "7",
                    fill: "white"
                  })
                ])
              ])
            ], -1)),
            _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.runtimeData.chatInfo.show.name), 1),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.list[_ctx.list.length - 1] ? _ctx.$t('chat_last_msg', {
                            time: Intl.DateTimeFormat(_ctx.trueLang,
                            { hour: "numeric", minute: "numeric", second: "numeric" }).format(new Date(_ctx.list[_ctx.list.length
                            - 1].time *
                            1000))
                            }) : _ctx.$t('chat_no_msg')), 1)
            ])
          ], 2)
        ]),
        _: 1
      }, 8, ["danmus", "speeds", "loop"])
    ])
  ], 2))
}