import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "main" }
const _hoisted_2 = ["data-id"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = {
  key: 3,
  class: "download-bar",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_7 = ["stroke-dasharray"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_FileBody = _resolveComponent("FileBody", true)!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass((_ctx.item.type === 2 ? ' folder' : '') + ((_ctx.item.sub_list && _ctx.item.sub_list.length > 0) ? ' open' : '')),
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.loadFileDir(_ctx.item.id, _ctx.item.type)))
  }, [
    (_ctx.item.type === 2)
      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 0,
          icon: ['fas', 'folder']
        }))
      : _createCommentVNode("", true),
    (_ctx.item.type === 1)
      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 1,
          icon: ['fas', 'file']
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", null, _toDisplayString(_ctx.toHtml(_ctx.item.name)), 1),
      _createElementVNode("div", null, [
        _createElementVNode("span", {
          "data-id": _ctx.item.owner_uin
        }, _toDisplayString(_ctx.toHtml(_ctx.item.owner_name)), 9, _hoisted_2),
        _createElementVNode("span", null, _toDisplayString(_ctx.item.create_time === 0 ? '-' : Intl.DateTimeFormat(_ctx.trueLang,
                        { year: 'numeric', month: "short", day: "numeric" })
                        .format(new Date(_ctx.item.create_time * 1000))), 1),
        (!_ctx.item.dead_time && _ctx.item.dead_time)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(((_ctx.item.dead_time -
                        _ctx.item.create_time / 86400) - 1) + _ctx.$t('chat_chat_info_dead_day')), 1))
          : _createCommentVNode("", true),
        (_ctx.item.type === 2)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t('chat_chat_info_file_num', _ctx.item.size, { num: _ctx.item.size })), 1))
          : _createCommentVNode("", true),
        (_ctx.item.type === 1)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.getSize(_ctx.item.size)), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.item.type === 1 && _ctx.item.downloadingPercentage === undefined)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "download",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.getFile(_ctx.item)))
        }, [
          _createVNode(_component_font_awesome_icon, { icon: ['fas', 'angle-down'] })
        ]))
      : _createCommentVNode("", true),
    (_ctx.item.downloadingPercentage !== undefined)
      ? (_openBlock(), _createElementBlock("svg", _hoisted_6, [
          _cache[2] || (_cache[2] = _createElementVNode("circle", {
            cx: "50%",
            cy: "50%",
            r: "40%",
            "stroke-width": "15%",
            fill: "none",
            "stroke-linecap": "round"
          }, null, -1)),
          _createElementVNode("circle", {
            cx: "50%",
            cy: "50%",
            r: "40%",
            "stroke-width": "15%",
            fill: "none",
            "stroke-dasharray": _ctx.item.downloadingPercentage === undefined ?
            '0,10000' : `${Math.floor(2 * Math.PI * 25) * _ctx.item.downloadingPercentage / 100},10000`
          }, null, 8, _hoisted_7)
        ]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass((_ctx.item.sub_list !== undefined ? 'sub_file ' : '') + 'group-files')
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.sub_list, (sub_item) => {
        return (_openBlock(), _createElementBlock("div", {
          key: 'sub_file-' + sub_item.id
        }, [
          _createVNode(_component_FileBody, {
            chat: _ctx.chat,
            item: sub_item,
            parent: _ctx.item.id
          }, null, 8, ["chat", "item", "parent"])
        ]))
      }), 128))
    ], 2), [
      [_vShow, _ctx.item.sub_item_show !== false && _ctx.item.sub_list !== undefined]
    ])
  ], 2))
}