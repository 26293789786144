import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "script-view" }
const _hoisted_2 = { class: "list" }
const _hoisted_3 = { class: "list-body" }
const _hoisted_4 = ["id"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 0,
  style: {"font-size":"0.7rem"}
}
const _hoisted_7 = {
  key: 1,
  style: {"font-size":"0.7rem"}
}
const _hoisted_8 = ["onClick"]
const _hoisted_9 = {
  key: 0,
  class: "editor-main"
}
const _hoisted_10 = {
  key: 0,
  class: "inner"
}
const _hoisted_11 = { class: "save-controller" }
const _hoisted_12 = { class: "opt-item" }
const _hoisted_13 = { value: "message" }
const _hoisted_14 = { value: "userFlush" }
const _hoisted_15 = { value: "newNotice" }
const _hoisted_16 = {
  key: 1,
  class: "editor-main notice"
}
const _hoisted_17 = { class: "ss-card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_prism_editor = _resolveComponent("prism-editor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("header", null, [
        _createElementVNode("div", null, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('title_scripts')), 1),
          _createVNode(_component_font_awesome_icon, {
            icon: ['fas', 'globe'],
            style: {"margin-right":"15px"}
          }),
          _createVNode(_component_font_awesome_icon, {
            onClick: _ctx.cnewScript,
            icon: ['fas', 'add']
          }, null, 8, ["onClick"])
        ]),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('scripts_tip')), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.savedList, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            id: item.title,
            class: _normalizeClass({selected: (_ctx.select?.title == item.title && _ctx.editScript)}),
            key: index
          }, [
            _createElementVNode("div", null, [
              _createElementVNode("div", {
                style: {"flex":"1"},
                onClick: ($event: any) => (_ctx.selectItem(item))
              }, [
                _createElementVNode("h2", null, [
                  _createTextVNode(_toDisplayString(item.title) + " ", 1),
                  (item.enabled)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t('statue_enabled')), 1))
                    : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t('statue_disabled')), 1))
                ]),
                _createElementVNode("span", null, [
                  (item.inner)
                    ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                        key: 0,
                        style: {"margin-right":"5px"},
                        icon: ['fas', 'star']
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_font_awesome_icon, { icon: ['fas', 'code-branch'] }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('scripts_run_' + item.condition)) + _toDisplayString(_ctx.$t('scripts_run_trigger')), 1)
                ])
              ], 8, _hoisted_5),
              _createElementVNode("div", {
                style: _normalizeStyle(item.enabled ? 'color: #ed6a5e;' : ''),
                onClick: ($event: any) => {item.enabled = !item.enabled;_ctx.updateSave();}
              }, [
                _createVNode(_component_font_awesome_icon, {
                  icon: ['fas', item.enabled ? 'stop' : 'play']
                }, null, 8, ["icon"])
              ], 12, _hoisted_8)
            ])
          ], 10, _hoisted_4))
        }), 128))
      ])
    ]),
    (_ctx.editScript)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          (_ctx.select?.inner)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createVNode(_component_font_awesome_icon, { icon: ['fas', 'info'] }),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('a_inner_script')), 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'code-branch'] }),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('scripts_run_condition')), 1),
            _createElementVNode("div", _hoisted_12, [
              _withDirectives(_createElementVNode("select", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.condition) = $event))
              }, [
                _createElementVNode("option", _hoisted_13, _toDisplayString(_ctx.$t('scripts_run_message')), 1),
                _createElementVNode("option", _hoisted_14, _toDisplayString(_ctx.$t('scripts_run_userFlush')), 1),
                _createElementVNode("option", _hoisted_15, _toDisplayString(_ctx.$t('scripts_run_newNotice')), 1)
              ], 512), [
                [_vModelSelect, _ctx.condition]
              ])
            ]),
            _cache[5] || (_cache[5] = _createElementVNode("div", { style: {"flex":"1"} }, null, -1)),
            _createElementVNode("button", {
              class: "ss-button",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)))
            }, [
              _createVNode(_component_font_awesome_icon, { icon: ['fas', 'save'] }),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('scripts_run_save')), 1)
            ]),
            (!_ctx.select?.inner)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "ss-button",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => {_ctx.editScript = false;_ctx.remove(_ctx.select?.title);})
                }, [
                  (!_ctx.select)
                    ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                        key: 0,
                        icon: ['fas', 'times']
                      }))
                    : (_openBlock(), _createBlock(_component_font_awesome_icon, {
                        key: 1,
                        icon: ['fas', 'trash-alt']
                      }))
                ]))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_prism_editor, {
            class: "editor",
            modelValue: _ctx.script,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.script) = $event)),
            highlight: _ctx.highlighter,
            "line-numbers": ""
          }, null, 8, ["modelValue", "highlight"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'info-circle'] }),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('scripts_run_notice')), 1),
            _createElementVNode("button", {
              class: "ss-button",
              style: {"width":"100%","margin-top":"10px"},
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.openLink('https://github.com/Stapxs/Stapxs-QQ-Lite-2.0/blob/next/src/pages/Scripts.vue')))
            }, " Github ")
          ])
        ]))
  ]))
}