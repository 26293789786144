import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  id: "shell-pan",
  class: "shell-pan"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 0,
  style: {"color":"yellow"}
}
const _hoisted_6 = { style: {"color":"yellow","opacity":"0.7"} }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = {
  key: 0,
  class: "line-head"
}
const _hoisted_9 = { style: {"color":"var(--color-main-0)"} }
const _hoisted_10 = { style: {"color":"var(--color-main-1)"} }
const _hoisted_11 = { key: 3 }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = { class: "shell-input" }
const _hoisted_14 = { class: "line-head" }
const _hoisted_15 = { style: {"color":"var(--color-main-0)"} }
const _hoisted_16 = {
  key: 0,
  style: {"color":"var(--color-main-2)"}
}
const _hoisted_17 = { style: {"color":"var(--color-main-1)"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    id: "chat-pan",
    class: _normalizeClass('chat-pan' + (_ctx.runtimeData.tags.openSideBar ? ' open': '') + (_ctx.runtimeData.sysConfig.opt_no_window ? ' withBar': ''))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.runtimeData.messageList, (msg, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: msg.message_id
          }, [
            (msg.post_type == 'message' || msg.post_type == 'message_sent')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass('shell-msg' + (msg.revoke ? ' revoke' : '') + (_ctx.tags.replyId == msg.message_id ? ' reply' : '')),
                  style: {"cursor":"pointer"}
                }, [
                  _createElementVNode("span", {
                    onClick: ($event: any) => (_ctx.copy(msg.sender.user_id)),
                    class: _normalizeClass('sname s' + msg.sender.role + (_ctx.runtimeData.loginInfo.uin == msg.sender.user_id ? ' smine' : ''))
                  }, _toDisplayString(msg.sender.card ? msg.sender.card : msg.sender.nickname) + _toDisplayString(_ctx.hasReply(msg) ?? '') + _toDisplayString(msg.sub_type == 'friend' ? (_ctx.runtimeData.loginInfo.uin == msg.sender.user_id ? _ctx.runtimeData.loginInfo.nickname : _ctx.runtimeData.chatInfo.show.name) : '') + _toDisplayString(msg.sender.user_id == 0 ? '' : ': '), 11, _hoisted_2),
                  _createElementVNode("span", {
                    class: "smsg",
                    onClick: ($event: any) => (_ctx.copy(msg.message_id))
                  }, _toDisplayString(_ctx.getMsgRawTxt(msg.message)), 9, _hoisted_3),
                  _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1))
                ], 2))
              : (msg.post_type == 'notice')
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    (msg.sub_type == 'recall')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                          _cache[4] || (_cache[4] = _createTextVNode(":: ")),
                          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.getRecallName(msg.operator_id)), 1),
                          _cache[5] || (_cache[5] = _createTextVNode(" recalled a message."))
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : (msg.commandLine)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      (index == 2)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            _createElementVNode("div", null, [
                              _createElementVNode("span", null, [
                                _createVNode(_component_font_awesome_icon, { icon: ['fas', 'folder-open'] }),
                                _createTextVNode(_toDisplayString(_ctx.runtimeData.chatInfo.show.name), 1)
                              ]),
                              _createElementVNode("span", _hoisted_9, [
                                _createVNode(_component_font_awesome_icon, { icon: ['fas', 'plug'] }),
                                _createTextVNode(_toDisplayString(_ctx.runtimeData.sysConfig.address), 1)
                              ])
                            ]),
                            _cache[6] || (_cache[6] = _createElementVNode("div", { style: {"flex":"1"} }, null, -1)),
                            _createElementVNode("div", null, [
                              _createElementVNode("span", _hoisted_10, [
                                _createTextVNode(_toDisplayString(_ctx.packageInfo.version), 1),
                                _createVNode(_component_font_awesome_icon, { icon: ['fas', 'code-branch'] })
                              ]),
                              _createElementVNode("span", null, [
                                _createTextVNode(_toDisplayString(msg.time.time), 1),
                                _createVNode(_component_font_awesome_icon, { icon: ['fas', 'clock'] })
                              ])
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      _cache[7] || (_cache[7] = _createElementVNode("a", { class: "command-start" }, "• ", -1)),
                      _createElementVNode("span", null, _toDisplayString(msg.str), 1)
                    ]))
                  : (msg.commandOut)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                        (msg.html)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              innerHTML: msg.html
                            }, null, 8, _hoisted_12))
                          : (_openBlock(), _createElementBlock("span", {
                              key: 1,
                              style: _normalizeStyle('color:' + msg.color)
                            }, _toDisplayString(msg.str), 5))
                      ]))
                    : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", null, [
            _createElementVNode("span", null, [
              _createVNode(_component_font_awesome_icon, { icon: ['fas', 'folder-open'] }),
              _createTextVNode(_toDisplayString(_ctx.runtimeData.chatInfo.show.name) + _toDisplayString(_ctx.tags.replyName ? ' -> ' + _ctx.tags.replyName : ''), 1)
            ]),
            _createElementVNode("span", _hoisted_15, [
              _createVNode(_component_font_awesome_icon, { icon: ['fas', 'plug'] }),
              _createTextVNode(_toDisplayString(_ctx.runtimeData.sysConfig.address), 1)
            ])
          ]),
          _cache[8] || (_cache[8] = _createElementVNode("div", { style: {"flex":"1"} }, null, -1)),
          _createElementVNode("div", null, [
            (_ctx.tags.newMsg > 0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_16, [
                  _createTextVNode(_toDisplayString(_ctx.tags.newMsg), 1),
                  _createVNode(_component_font_awesome_icon, { icon: ['fas', 'envelope'] })
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("span", _hoisted_17, [
              _createTextVNode(_toDisplayString(_ctx.packageInfo.version), 1),
              _createVNode(_component_font_awesome_icon, { icon: ['fas', 'code-branch'] })
            ]),
            _createElementVNode("span", null, [
              _createTextVNode(_toDisplayString(_ctx.timeShow), 1),
              _createVNode(_component_font_awesome_icon, { icon: ['fas', 'clock'] })
            ])
          ])
        ]),
        _cache[9] || (_cache[9] = _createElementVNode("a", { class: "command-start" }, "• ", -1)),
        _withDirectives(_createElementVNode("input", {
          onKeyup: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.sendMsg && _ctx.sendMsg(...args))),
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.msg) = $event)),
          onPaste: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.addImg && _ctx.addImg(...args))),
          id: "msgInput"
        }, null, 544), [
          [_vModelText, _ctx.msg]
        ])
      ])
    ])
  ], 2))
}