import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, vModelText as _vModelText, vModelCheckbox as _vModelCheckbox, withModifiers as _withModifiers, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, TransitionGroup as _TransitionGroup, withCtx as _withCtx, mergeProps as _mergeProps, Transition as _Transition, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "top-bar",
  name: "appbar"
}
const _hoisted_2 = { class: "controller" }
const _hoisted_3 = {
  key: 1,
  class: "controller mac-controller"
}
const _hoisted_4 = { id: "base-app" }
const _hoisted_5 = { class: "main-body" }
const _hoisted_6 = ["name"]
const _hoisted_7 = { class: "home-body" }
const _hoisted_8 = { class: "login-pan-card ss-card" }
const _hoisted_9 = ["placeholder"]
const _hoisted_10 = ["placeholder"]
const _hoisted_11 = { style: {"display":"flex"} }
const _hoisted_12 = { class: "default" }
const _hoisted_13 = {
  class: "default",
  style: {"justify-content":"flex-end"}
}
const _hoisted_14 = {
  href: "https://github.com/Stapxs/Stapxs-QQ-Lite-2.0#%E5%BF%AB%E9%80%9F%E4%BD%BF%E7%94%A8",
  target: "_blank",
  style: {"margin-bottom":"-20px"}
}
const _hoisted_15 = {
  key: 1,
  id: "messageTab"
}
const _hoisted_16 = { key: 2 }
const _hoisted_17 = { class: "opt-main-tab" }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = {
  key: 0,
  class: "pop-box"
}
const _hoisted_20 = { key: 0 }
const _hoisted_21 = ["innerHTML"]
const _hoisted_22 = { class: "button" }
const _hoisted_23 = ["onClick"]
const _hoisted_24 = { class: "pop-box-more" }
const _hoisted_25 = ["data-id"]
const _hoisted_26 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_Messages = _resolveComponent("Messages")!
  const _component_Friends = _resolveComponent("Friends")!
  const _component_Scripts = _resolveComponent("Scripts")!
  const _component_Options = _resolveComponent("Options")!
  const _component_viewer = _resolveComponent("viewer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.runtimeData.sysConfig.opt_no_window)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", {
            class: "bar-button",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.barMainClick()))
          }),
          _cache[18] || (_cache[18] = _createElementVNode("div", { class: "space" }, null, -1)),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.controllWin('minimize'))),
              class: "min"
            }, [
              _createVNode(_component_font_awesome_icon, { icon: ['fas', 'minus'] })
            ]),
            _createElementVNode("div", {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.controllWin('close'))),
              class: "close"
            }, [
              _createVNode(_component_font_awesome_icon, { icon: ['fas', 'xmark'] })
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.runtimeData.tags.platform == 'darwin')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("ul", {
          style: _normalizeStyle(_ctx.get('fs_adaptation') > 0 ? `padding-bottom: ${_ctx.get('fs_adaptation')}px;` : '')
        }, [
          _createElementVNode("li", {
            id: "bar-home",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changeTab('主页', 'Home', false))),
            class: _normalizeClass((_ctx.tags.page == 'Home' ? 'active' : '') + (_ctx.loginInfo.status ? ' hiden-home' : ''))
          }, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'home'] })
          ], 2),
          _createElementVNode("li", {
            id: "bar-msg",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.changeTab('信息', 'Messages', true))),
            class: _normalizeClass(_ctx.tags.page == 'Messages' ? 'active' : '')
          }, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'envelope'] })
          ], 2),
          _createElementVNode("li", {
            id: "bar-friends",
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.changeTab('列表', 'Friends', true))),
            class: _normalizeClass(_ctx.tags.page == 'Friends' ? 'active' : '')
          }, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'user'] })
          ], 2),
          _cache[19] || (_cache[19] = _createElementVNode("div", { class: "side-bar-space" }, null, -1)),
          _withDirectives(_createElementVNode("li", {
            id: "bar-friends",
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.changeTab('脚本', 'Scripts', false))),
            class: _normalizeClass(_ctx.tags.page == 'Scripts' ? 'active' : '')
          }, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'file-code'] })
          ], 2), [
            [_vShow, _ctx.runtimeData.sysConfig.append_scripts]
          ]),
          _createElementVNode("li", {
            onClick: _cache[7] || (_cache[7] = ($event: any) => {_ctx.changeTab('设置', 'Options', false);_ctx.Connector.send('get_version_info', {}, 'getVersionInfo')}),
            class: _normalizeClass(_ctx.tags.page == 'Options' ? 'active' : '')
          }, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'gear'] })
          ], 2)
        ], 4),
        _createElementVNode("div", {
          style: _normalizeStyle(_ctx.get('fs_adaptation') > 0 ? `height: calc(100% - ${75 + Number(_ctx.get('fs_adaptation'))}px);` : '')
        }, [
          (_ctx.tags.page == 'Home')
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                name: _ctx.$t('home_title')
              }, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_font_awesome_icon, { icon: ['fas', 'circle-nodes'] }),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('home_card_title')), 1),
                    _createElementVNode("form", {
                      onSubmit: [
                        _cache[15] || (_cache[15] = _withModifiers(() => {}, ["prevent"])),
                        _cache[16] || (_cache[16] = 
//@ts-ignore
(...args) => (_ctx.connect && _ctx.connect(...args)))
                      ]
                    }, [
                      _createElementVNode("label", null, [
                        _createVNode(_component_font_awesome_icon, { icon: ['fas', 'link'] }),
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.loginInfo.address) = $event)),
                          placeholder: _ctx.$t('home_card_address'),
                          class: "ss-input",
                          id: "sev_address",
                          autocomplete: "off"
                        }, null, 8, _hoisted_9), [
                          [_vModelText, _ctx.loginInfo.address]
                        ])
                      ]),
                      _createElementVNode("label", null, [
                        _createVNode(_component_font_awesome_icon, { icon: ['fas', 'lock'] }),
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.loginInfo.token) = $event)),
                          placeholder: _ctx.$t('home_card_key'),
                          class: "ss-input",
                          type: "password",
                          id: "access_token",
                          autocomplete: "off"
                        }, null, 8, _hoisted_10), [
                          [_vModelText, _ctx.loginInfo.token]
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("label", _hoisted_12, [
                          _withDirectives(_createElementVNode("input", {
                            id: "in_",
                            type: "checkbox",
                            name: "save_password",
                            onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.savePassword && _ctx.savePassword(...args))),
                            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.tags.savePassword) = $event))
                          }, null, 512), [
                            [_vModelCheckbox, _ctx.tags.savePassword]
                          ]),
                          _createElementVNode("a", null, _toDisplayString(_ctx.$t('home_card_save_pwd')), 1)
                        ]),
                        _cache[20] || (_cache[20] = _createElementVNode("div", { style: {"flex":"1"} }, null, -1)),
                        _createElementVNode("label", _hoisted_13, [
                          _withDirectives(_createElementVNode("input", {
                            type: "checkbox",
                            name: "auto_connect",
                            onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.saveAutoConnect && _ctx.saveAutoConnect(...args))),
                            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.runtimeData.sysConfig.auto_connect) = $event))
                          }, null, 512), [
                            [_vModelCheckbox, _ctx.runtimeData.sysConfig.auto_connect]
                          ]),
                          _createElementVNode("a", null, _toDisplayString(_ctx.$t('home_card_auto_con')), 1)
                        ])
                      ]),
                      _createElementVNode("button", {
                        onMousemove: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.afd && _ctx.afd(...args))),
                        id: "connect_btn",
                        class: "ss-button",
                        type: "submit"
                      }, _toDisplayString(_ctx.$t('home_card_connect')), 33)
                    ], 32),
                    _createElementVNode("a", _hoisted_14, _toDisplayString(_ctx.$t('home_card_how_to_connect')), 1),
                    _cache[21] || (_cache[21] = _createStaticVNode("<div class=\"wave-pan\" style=\"margin-left:-30px;\" data-v-51ddc8cb><svg id=\"login-wave\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 24 170 70\" preserveAspectRatio=\"none\" shape-rendering=\"auto\" data-v-51ddc8cb><defs data-v-51ddc8cb><path id=\"gentle-wave\" d=\"M -160 44 c 30 0 58 -18 88 -18 s 58 18 88 18 s 58 -18 88 -18 s 58 18 88 18 v 44 h -352 Z\" data-v-51ddc8cb></path></defs><g class=\"parallax\" data-v-51ddc8cb><use xlink:href=\"#gentle-wave\" x=\"83\" y=\"0\" data-v-51ddc8cb></use><use xlink:href=\"#gentle-wave\" x=\"135\" y=\"3\" data-v-51ddc8cb></use><use xlink:href=\"#gentle-wave\" x=\"185\" y=\"5\" data-v-51ddc8cb></use><use xlink:href=\"#gentle-wave\" x=\"54\" y=\"7\" data-v-51ddc8cb></use></g></svg></div>", 1))
                  ])
                ])
              ], 8, _hoisted_6))
            : _createCommentVNode("", true),
          (_ctx.tags.page == 'Messages')
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                _createVNode(_component_Messages, {
                  chat: _ctx.runtimeData.chatInfo,
                  onUserClick: _ctx.changeChat,
                  onLoadHistory: _ctx.loadHistory
                }, null, 8, ["chat", "onUserClick", "onLoadHistory"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.tags.page == 'Friends')
            ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                _createVNode(_component_Friends, {
                  list: _ctx.runtimeData.userList,
                  onLoadHistory: _ctx.loadHistory,
                  onUserClick: _ctx.changeChat
                }, null, 8, ["list", "onLoadHistory", "onUserClick"])
              ]))
            : _createCommentVNode("", true),
          _withDirectives(_createElementVNode("div", null, [
            _createVNode(_component_Scripts)
          ], 512), [
            [_vShow, _ctx.tags.page == 'Scripts' && _ctx.runtimeData.sysConfig.append_scripts]
          ]),
          _createElementVNode("div", _hoisted_17, [
            _createVNode(_component_Options, {
              show: _ctx.tags.page == 'Options',
              class: _normalizeClass(_ctx.tags.page == 'Options' ? 'active' : ''),
              config: _ctx.runtimeData.sysConfig,
              info: _ctx.runtimeData.loginInfo,
              status: _ctx.loginInfo
            }, null, 8, ["show", "class", "config", "info", "status"])
          ])
        ], 4)
      ]),
      (_ctx.loginInfo.status && _ctx.runtimeData.chatInfo && _ctx.runtimeData.chatInfo.show.id != 0)
        ? _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.runtimeData.pageView.chatView), {
            key: 0,
            ref: "chat",
            mumberInfo: _ctx.runtimeData.chatInfo.info.now_member_info == undefined ? {} : _ctx.runtimeData.chatInfo.info.now_member_info,
            mergeList: _ctx.runtimeData.mergeMessageList,
            list: _ctx.runtimeData.messageList,
            chat: _ctx.runtimeData.chatInfo,
            onUserClick: _ctx.changeChat
          }, null, 40, ["mumberInfo", "mergeList", "list", "chat", "onUserClick"])), [
            [_vShow, _ctx.tags.showChat]
          ])
        : _createCommentVNode("", true),
      _createVNode(_TransitionGroup, {
        class: "app-msg",
        name: "appmsg",
        tag: "div"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.appMsgs, (msg) => {
            return (_openBlock(), _createElementBlock("div", {
              key: 'appmsg-' + msg.id
            }, [
              _createElementVNode("div", null, [
                _createVNode(_component_font_awesome_icon, {
                  icon: ['fas', msg.svg]
                }, null, 8, ["icon"])
              ]),
              _createElementVNode("a", null, _toDisplayString(msg.text), 1),
              (!msg.autoClose)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    onClick: ($event: any) => (_ctx.popInfo.remove(msg.id))
                  }, [
                    _createVNode(_component_font_awesome_icon, { icon: ['fas', 'xmark'] })
                  ], 8, _hoisted_18))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ]),
        _: 1
      }),
      _createVNode(_Transition, null, {
        default: _withCtx(() => [
          (_ctx.runtimeData.popBoxList.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                _createElementVNode("div", {
                  class: _normalizeClass('pop-box-body ss-card' + (_ctx.runtimeData.popBoxList[0].full ? ' full' : '') + (_ctx.get('option_view_no_window') == true ? '' : ' window')),
                  style: _normalizeStyle('transform: translate(-50%, calc(-50% - ' + ((_ctx.runtimeData.popBoxList.length > 3 ? 3 : _ctx.runtimeData.popBoxList.length) * 10) + 'px));' + (_ctx.get('fs_adaptation') > 0 ? ` margin-bottom: ${40 + Number(_ctx.get('fs_adaptation'))}px;` : ''))
                }, [
                  _withDirectives(_createElementVNode("header", null, [
                    (_ctx.runtimeData.popBoxList[0].svg != undefined)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                          _createVNode(_component_font_awesome_icon, {
                            icon: ['fas', _ctx.runtimeData.popBoxList[0].svg]
                          }, null, 8, ["icon"])
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("a", null, _toDisplayString(_ctx.runtimeData.popBoxList[0].title), 1),
                    _createVNode(_component_font_awesome_icon, {
                      onClick: _ctx.removePopBox,
                      icon: ['fas','xmark']
                    }, null, 8, ["onClick"])
                  ], 512), [
                    [_vShow, _ctx.runtimeData.popBoxList[0].title != undefined]
                  ]),
                  (_ctx.runtimeData.popBoxList[0].html)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        innerHTML: _ctx.runtimeData.popBoxList[0].html
                      }, null, 8, _hoisted_21))
                    : (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.runtimeData.popBoxList[0].template), _mergeProps({
                        key: 1,
                        data: _ctx.runtimeData.popBoxList[0].data
                      }, _ctx.runtimeData.popBoxList[0].templateValue), null, 16, ["data"])),
                  _withDirectives(_createElementVNode("div", _hoisted_22, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.runtimeData.popBoxList[0].button, (button, index) => {
                      return (_openBlock(), _createElementBlock("button", {
                        class: _normalizeClass('ss-button' + (button.master == true ? ' master' : '')),
                        key: 'pop-box-btn' + index,
                        onClick: button.fun
                      }, _toDisplayString(button.text), 11, _hoisted_23))
                    }), 128))
                  ], 512), [
                    [_vShow, _ctx.runtimeData.popBoxList[0].button]
                  ]),
                  _createElementVNode("div", _hoisted_24, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.runtimeData.popBoxList.length, (index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        "data-id": index,
                        key: 'pop-more-' + index,
                        class: _normalizeClass(index > _ctx.runtimeData.popBoxList.length - 1 ? 'hid' : ''),
                        style: _normalizeStyle('margin:-' + (2*(index-1)) + 'px ' + ((20*index-1)-(2*(index-1))) + 'px 0 ' + ((20*index-1)-(2*(index-1))) + 'px;')
                      }, null, 14, _hoisted_25))
                    }), 128))
                  ])
                ], 6),
                _createElementVNode("div", {
                  onClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.popQuickClose(_ctx.runtimeData.popBoxList[0].allowQuickClose)))
                })
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_viewer, {
        class: "viewer",
        ref: "viewer",
        options: _ctx.viewerOpt,
        images: _ctx.runtimeData.chatInfo.info.image_list,
        onInited: _ctx.viewerInited,
        onHide: _ctx.viewerHide,
        onShow: _ctx.viewerShow
      }, {
        default: _withCtx((scope) => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scope.images, (info) => {
            return (_openBlock(), _createElementBlock("img", {
              src: info.img_url,
              key: 'imgView-' + info.index
            }, null, 8, _hoisted_26))
          }), 128))
        ]),
        _: 1
      }, 8, ["options", "images", "onInited", "onHide", "onShow"])
    ])
  ], 64))
}