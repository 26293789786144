import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "info" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "base",
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showAll = !_ctx.showAll))
  }, [
    _createElementVNode("header", null, [
      _createVNode(_component_font_awesome_icon, { icon: ['fas', 'bookmark'] }),
      _createElementVNode("span", null, _toDisplayString(_ctx.$t('chat_chat_info_notice')), 1),
      _cache[2] || (_cache[2] = _createElementVNode("div", { style: {"flex":"1"} }, null, -1)),
      _createElementVNode("span", null, _toDisplayString(Intl.DateTimeFormat(_ctx.trueLang, { month: "short", day: "numeric", hour: "numeric", minute: "numeric" })
                    .format(_ctx.data.time)), 1)
    ]),
    _createElementVNode("div", {
      id: 'bulletins-msg-' + _ctx.index,
      class: _normalizeClass('body' + (!_ctx.showAll ? '' : ' all'))
    }, [
      _createElementVNode("span", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.textClick && _ctx.textClick(...args))),
        innerHTML: _ctx.parseText(_ctx.data.content[0])
      }, null, 8, _hoisted_2)
    ], 10, _hoisted_1),
    _withDirectives(_createElementVNode("span", null, _toDisplayString(_ctx.$t('bulletin_show_tip')), 513), [
      [_vShow, _ctx.needShow && !_ctx.showAll]
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("img", {
        src: 'https://q1.qlogo.cn/g?b=qq&s=0&nk=' + _ctx.data.sender
      }, null, 8, _hoisted_4),
      _createElementVNode("a", null, _toDisplayString((_ctx.runtimeData.chatInfo.info.group_members.filter((item) => {
                return Number(item.user_id) ===
                    Number(_ctx.data.sender)
            }))[0].nickname), 1),
      _cache[3] || (_cache[3] = _createElementVNode("div", null, null, -1)),
      (_ctx.data.is_read)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t('chat_chat_info_bulletin_read', {
                    isRead: _ctx.data.is_read ? _ctx.$t('chat_chat_info_bulletin_readed') : _ctx.$t('chat_chat_info_bulletin_noread'),
                readNum: _ctx.data.read_num
            })), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}