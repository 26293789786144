import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, vModelSelect as _vModelSelect, createCommentVNode as _createCommentVNode, vModelText as _vModelText } from "vue"

const _hoisted_1 = { class: "opt-page" }
const _hoisted_2 = { class: "ss-card" }
const _hoisted_3 = { class: "opt-item" }
const _hoisted_4 = { class: "ss-switch" }
const _hoisted_5 = { class: "ss-card" }
const _hoisted_6 = { class: "tip" }
const _hoisted_7 = { class: "opt-item" }
const _hoisted_8 = { class: "ss-switch" }
const _hoisted_9 = { class: "opt-item" }
const _hoisted_10 = ["value"]
const _hoisted_11 = { class: "opt-item" }
const _hoisted_12 = {
  key: 0,
  value: ""
}
const _hoisted_13 = ["value"]
const _hoisted_14 = { class: "ss-card" }
const _hoisted_15 = { class: "opt-item" }
const _hoisted_16 = { value: "err" }
const _hoisted_17 = { value: "debug" }
const _hoisted_18 = { value: "info" }
const _hoisted_19 = { value: "all" }
const _hoisted_20 = { class: "opt-item" }
const _hoisted_21 = { class: "ss-switch" }
const _hoisted_22 = { class: "ss-card" }
const _hoisted_23 = { class: "opt-item" }
const _hoisted_24 = { class: "opt-item" }
const _hoisted_25 = { class: "opt-item" }
const _hoisted_26 = { class: "opt-item" }
const _hoisted_27 = { class: "opt-item" }
const _hoisted_28 = { class: "ss-switch" }
const _hoisted_29 = {
  key: 0,
  class: "opt-item"
}
const _hoisted_30 = { class: "ss-card" }
const _hoisted_31 = { class: "opt-item" }
const _hoisted_32 = { class: "opt-item" }
const _hoisted_33 = { class: "opt-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("header", null, _toDisplayString(_ctx.$t('option_dev_append')), 1),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_font_awesome_icon, { icon: ['fas', 'code'] }),
        _createElementVNode("div", null, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('title_scripts')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_dev_scripts_tip')), 1)
        ]),
        _createElementVNode("label", _hoisted_4, [
          _withDirectives(_createElementVNode("input", {
            type: "checkbox",
            onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args))),
            name: "append_scripts",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.runtimeData.sysConfig.append_scripts) = $event))
          }, null, 544), [
            [_vModelCheckbox, _ctx.runtimeData.sysConfig.append_scripts]
          ]),
          _cache[25] || (_cache[25] = _createElementVNode("div", null, [
            _createElementVNode("div")
          ], -1))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("header", null, _toDisplayString(_ctx.$t('option_dev_connect')), 1),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('option_dev_connect_tip')), 1),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_font_awesome_icon, { icon: ['fas', 'heart'] }),
        _createElementVNode("div", null, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_dev_connect_beat')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_dev_connect_beat_tip')), 1)
        ]),
        _createElementVNode("label", _hoisted_8, [
          _withDirectives(_createElementVNode("input", {
            type: "checkbox",
            onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args))),
            name: "connect_beat",
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.runtimeData.sysConfig.connect_beat) = $event))
          }, null, 544), [
            [_vModelCheckbox, _ctx.runtimeData.sysConfig.connect_beat]
          ]),
          _cache[26] || (_cache[26] = _createElementVNode("div", null, [
            _createElementVNode("div")
          ], -1))
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_font_awesome_icon, { icon: ['fas', 'clipboard-list'] }),
        _createElementVNode("div", null, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_dev_msg_type')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_dev_msg_type_tip')), 1)
        ]),
        _withDirectives(_createElementVNode("select", {
          onChange: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args))),
          name: "msg_type",
          title: "msg_type",
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.runtimeData.sysConfig.msgType) = $event))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.BotMsgType, (item) => {
            return _withDirectives((_openBlock(), _createElementBlock("option", {
              value: item,
              key: item
            }, _toDisplayString(_ctx.getBotTypeName(item)), 9, _hoisted_10)), [
              [_vShow, (typeof item == 'number')]
            ])
          }), 128))
        ], 544), [
          [_vModelSelect, _ctx.runtimeData.sysConfig.msgType]
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_font_awesome_icon, { icon: ['fas', 'gear'] }),
        _createElementVNode("div", null, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_dev_config_type')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_dev_config_type_tip')), 1)
        ]),
        _withDirectives(_createElementVNode("select", {
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.jsonMapName) = $event)),
          onChange: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.changeJsonMap && _ctx.changeJsonMap(...args)))
        }, [
          (_ctx.jsonMapName == '')
            ? (_openBlock(), _createElementBlock("option", _hoisted_12, _toDisplayString(_ctx.$t('option_dev_config_type_none')), 1))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getPathMapList(), (item) => {
            return (_openBlock(), _createElementBlock("option", {
              value: item,
              key: item
            }, _toDisplayString(item.replace('Chat', '')), 9, _hoisted_13))
          }), 128))
        ], 544), [
          [_vModelSelect, _ctx.jsonMapName]
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("header", null, _toDisplayString(_ctx.$t('option_dev_dev')), 1),
      _createElementVNode("div", _hoisted_15, [
        _createVNode(_component_font_awesome_icon, { icon: ['fas', 'book'] }),
        _createElementVNode("div", null, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_dev_log_level')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_dev_log_level_tip')), 1)
        ]),
        _withDirectives(_createElementVNode("select", {
          onChange: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args))),
          name: "log_level",
          title: "log_level",
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.runtimeData.sysConfig.log_level) = $event))
        }, [
          _createElementVNode("option", _hoisted_16, _toDisplayString(_ctx.$t('option_dev_log_level_err')), 1),
          _createElementVNode("option", _hoisted_17, _toDisplayString(_ctx.$t('option_dev_log_level_debug')), 1),
          _createElementVNode("option", _hoisted_18, _toDisplayString(_ctx.$t('option_dev_log_level_info')), 1),
          _createElementVNode("option", _hoisted_19, _toDisplayString(_ctx.$t('option_dev_log_level_all')), 1)
        ], 544), [
          [_vModelSelect, _ctx.runtimeData.sysConfig.log_level]
        ])
      ]),
      _createElementVNode("div", _hoisted_20, [
        _createVNode(_component_font_awesome_icon, { icon: ['fas', 'robot'] }),
        _createElementVNode("div", null, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_dev_debug_msg')), 1),
          _createElementVNode("span", null, [
            _createElementVNode("a", {
              style: {"cursor":"pointer"},
              onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.sendAbab && _ctx.sendAbab(...args)))
            }, _toDisplayString(_ctx.$t('option_dev_debug_msg_tip')), 1)
          ])
        ]),
        _createElementVNode("label", _hoisted_21, [
          _withDirectives(_createElementVNode("input", {
            type: "checkbox",
            onChange: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args))),
            name: "debug_msg",
            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.runtimeData.sysConfig.debug_msg) = $event))
          }, null, 544), [
            [_vModelCheckbox, _ctx.runtimeData.sysConfig.debug_msg]
          ]),
          _cache[27] || (_cache[27] = _createElementVNode("div", null, [
            _createElementVNode("div")
          ], -1))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_22, [
      _createElementVNode("header", null, _toDisplayString(_ctx.$t('option_dev_test')), 1),
      _createElementVNode("div", _hoisted_23, [
        _createVNode(_component_font_awesome_icon, { icon: ['fas', 'paper-plane'] }),
        _createElementVNode("div", null, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_dev_ws_send')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_dev_ws_send_tip')), 1)
        ]),
        _withDirectives(_createElementVNode("input", {
          class: "ss-input",
          style: {"width":"150px"},
          type: "text",
          onKeyup: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.sendTestWs && _ctx.sendTestWs(...args))),
          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.ws_text) = $event))
        }, null, 544), [
          [_vModelText, _ctx.ws_text]
        ])
      ]),
      _createElementVNode("div", _hoisted_24, [
        _createVNode(_component_font_awesome_icon, { icon: ['fas', 'envelope'] }),
        _createElementVNode("div", null, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_dev_appmsg')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_dev_appmsg_tip')), 1)
        ]),
        _withDirectives(_createElementVNode("input", {
          class: "ss-input",
          style: {"width":"150px"},
          type: "text",
          onKeyup: _cache[15] || (_cache[15] = 
//@ts-ignore
(...args) => (_ctx.sendTestAppmsg && _ctx.sendTestAppmsg(...args))),
          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.appmsg_text) = $event))
        }, null, 544), [
          [_vModelText, _ctx.appmsg_text]
        ])
      ]),
      _createElementVNode("div", _hoisted_25, [
        _createVNode(_component_font_awesome_icon, { icon: ['fas', 'file-invoice'] }),
        _createElementVNode("div", null, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_dev_runtime')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_dev_runtime_tip')), 1)
        ]),
        _createElementVNode("button", {
          style: {"width":"100px","font-size":"0.8rem"},
          class: "ss-button",
          onClick: _cache[17] || (_cache[17] = 
//@ts-ignore
(...args) => (_ctx.printRuntime && _ctx.printRuntime(...args)))
        }, _toDisplayString(_ctx.$t('option_dev_runtime_run')), 1)
      ]),
      _createElementVNode("div", _hoisted_26, [
        _createVNode(_component_font_awesome_icon, { icon: ['fas', 'screwdriver-wrench'] }),
        _createElementVNode("div", null, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_dev_debug')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_dev_debug_tip')), 1)
        ]),
        _createElementVNode("button", {
          style: {"width":"100px","font-size":"0.8rem"},
          class: "ss-button",
          onClick: _cache[18] || (_cache[18] = 
//@ts-ignore
(...args) => (_ctx.printVersionInfo && _ctx.printVersionInfo(...args)))
        }, _toDisplayString(_ctx.$t('option_dev_runtime_run')), 1)
      ]),
      _createElementVNode("div", _hoisted_27, [
        _createVNode(_component_font_awesome_icon, { icon: ['fas', 'mountain'] }),
        _createElementVNode("div", null, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_dev_ui_test')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_dev_ui_test_tip')), 1)
        ]),
        _createElementVNode("label", _hoisted_28, [
          _withDirectives(_createElementVNode("input", {
            type: "checkbox",
            onChange: _cache[19] || (_cache[19] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args))),
            name: "ui_test",
            "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.runtimeData.sysConfig.ui_test) = $event))
          }, null, 544), [
            [_vModelCheckbox, _ctx.runtimeData.sysConfig.ui_test]
          ]),
          _cache[28] || (_cache[28] = _createElementVNode("div", null, [
            _createElementVNode("div")
          ], -1))
        ])
      ]),
      (_ctx.runtimeData.tags.isElectron)
        ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'power-off'] }),
            _createElementVNode("div", null, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_dev_restart')), 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_dev_restart_tip')), 1)
            ]),
            _createElementVNode("button", {
              style: {"width":"100px","font-size":"0.8rem"},
              class: "ss-button",
              onClick: _cache[21] || (_cache[21] = 
//@ts-ignore
(...args) => (_ctx.restartapp && _ctx.restartapp(...args)))
            }, _toDisplayString(_ctx.$t('option_dev_runtime_run')), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_30, [
      _createElementVNode("header", null, _toDisplayString(_ctx.$t('option_dev_backup')), 1),
      _createElementVNode("div", _hoisted_31, [
        _createVNode(_component_font_awesome_icon, { icon: ['fas', 'download'] }),
        _createElementVNode("div", null, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_dev_get_backup')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_dev_get_backup_tip')), 1)
        ]),
        _createElementVNode("button", {
          onClick: _cache[22] || (_cache[22] = 
//@ts-ignore
(...args) => (_ctx.printSetUpInfo && _ctx.printSetUpInfo(...args))),
          style: {"width":"100px","font-size":"0.8rem"},
          class: "ss-button"
        }, _toDisplayString(_ctx.$t('option_dev_runtime_run')), 1)
      ]),
      _createElementVNode("div", _hoisted_32, [
        _createVNode(_component_font_awesome_icon, { icon: ['fas', 'upload'] }),
        _createElementVNode("div", null, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_dev_set_backup')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_dev_set_backup_tip')), 1)
        ]),
        _createElementVNode("button", {
          onClick: _cache[23] || (_cache[23] = 
//@ts-ignore
(...args) => (_ctx.importSetUpInfo && _ctx.importSetUpInfo(...args))),
          style: {"width":"100px","font-size":"0.8rem"},
          class: "ss-button"
        }, _toDisplayString(_ctx.$t('option_dev_runtime_run')), 1)
      ]),
      _createElementVNode("div", _hoisted_33, [
        _createVNode(_component_font_awesome_icon, { icon: ['fas', 'trash-arrow-up'] }),
        _createElementVNode("div", null, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_dev_reset')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_dev_reset_tip')), 1)
        ]),
        _createElementVNode("button", {
          onClick: _cache[24] || (_cache[24] = 
//@ts-ignore
(...args) => (_ctx.resetApp && _ctx.resetApp(...args))),
          style: {"width":"100px","font-size":"0.8rem"},
          class: "ss-button"
        }, _toDisplayString(_ctx.$t('option_dev_runtime_run')), 1)
      ])
    ])
  ]))
}