import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = {
  key: 0,
  class: "note-recall note-base"
}
const _hoisted_3 = {
  key: 1,
  class: "note-ban note-base"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 3,
  class: "note-time note-base"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: 'notice-' + _ctx.id,
    class: "note"
  }, [
    (_ctx.data.notice_type && _ctx.data.notice_type.indexOf('recall') >= 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("a", null, _toDisplayString(_ctx.info.name), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('chat_notice_recall')), 1),
          _cache[0] || (_cache[0] = _createElementVNode("div", null, null, -1))
        ]))
      : _createCommentVNode("", true),
    (_ctx.data.notice_type == 'group_ban')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_ctx.data.sub_type === 'ban')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.isMe(_ctx.data.user_id))
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('chat_member_type_admin')), 1),
                      _createElementVNode("a", null, " " + _toDisplayString(_ctx.getName(_ctx.data.operator_id)) + " ", 1),
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('note_ban_you')), 1),
                      _createElementVNode("span", null, " " + _toDisplayString(_ctx.fTime(_ctx.data.duration)), 1)
                    ], 64))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('note_ban_others')), 1),
                      _createElementVNode("a", null, " " + _toDisplayString(_ctx.getName(_ctx.data.user_id)) + " ", 1),
                      _createElementVNode("span", null, _toDisplayString(_ctx.fTime(_ctx.data.duration)), 1)
                    ], 64))
              ], 64))
            : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t('note_unban', { name: _ctx.isMe(_ctx.data.user_id) ? _ctx.$t('you') : _ctx.getName(_ctx.data.user_id) })), 1))
        ]))
      : _createCommentVNode("", true),
    (_ctx.data.sub_type === 'poke')
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "note-notify note-base",
          innerHTML: _ctx.data.str + '<div class=\'space\'</div>'
        }, null, 8, _hoisted_5))
      : _createCommentVNode("", true),
    (_ctx.data.sub_type === 'time')
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("a", null, _toDisplayString(Intl.DateTimeFormat(_ctx.trueLang, _ctx.getTimeConfig(new Date(_ctx.data.time * 1000)))
                    .format(new Date(_ctx.data.time * 1000))), 1)
        ]))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}