import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "opt-main" }
const _hoisted_2 = ["name"]
const _hoisted_3 = ["name"]
const _hoisted_4 = ["name"]
const _hoisted_5 = ["name"]
const _hoisted_6 = ["name"]
const _hoisted_7 = { class: "ss-card end-card" }
const _hoisted_8 = {
  style: {"width":"50px"},
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 274 259"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AboutPan = _resolveComponent("AboutPan")!
  const _component_OptAccount = _resolveComponent("OptAccount")!
  const _component_OptView = _resolveComponent("OptView")!
  const _component_OptFunction = _resolveComponent("OptFunction")!
  const _component_OptDev = _resolveComponent("OptDev")!
  const _component_BcTab = _resolveComponent("BcTab")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AboutPan, { showUI: "" }),
    _createElementVNode("div", null, [
      _withDirectives(_createVNode(_component_BcTab, {
        title: _ctx.$t('option_title'),
        class: "opt-tab"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            name: _ctx.$t('option_bar_account')
          }, [
            _createVNode(_component_OptAccount, { config: _ctx.config }, null, 8, ["config"])
          ], 8, _hoisted_2),
          _createElementVNode("div", {
            name: _ctx.$t('option_bar_view')
          }, [
            _createVNode(_component_OptView)
          ], 8, _hoisted_3),
          _createElementVNode("div", {
            name: _ctx.$t('option_bar_function')
          }, [
            _createVNode(_component_OptFunction, { config: _ctx.config }, null, 8, ["config"])
          ], 8, _hoisted_4),
          _createElementVNode("div", {
            name: _ctx.$t('option_bar_dev')
          }, [
            _createVNode(_component_OptDev)
          ], 8, _hoisted_5),
          _createElementVNode("div", {
            name: _ctx.$t('menu_about')
          }, [
            _createVNode(_component_AboutPan, {
              class: "opt-about",
              showUI: ""
            })
          ], 8, _hoisted_6)
        ]),
        _: 1
      }, 8, ["title"]), [
        [_vShow, _ctx.show]
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", null, [
          _createElementVNode("div", null, [
            _cache[0] || (_cache[0] = _createElementVNode("span", null, "Stapxs QQ Lite", -1)),
            _createElementVNode("a", null, _toDisplayString(_ctx.packageInfo.version), 1)
          ]),
          _cache[1] || (_cache[1] = _createElementVNode("span", null, "Copyright © 2022 - 2024 Stapx Steve [ 林槐 ]", -1))
        ]),
        (_openBlock(), _createElementBlock("svg", _hoisted_8, _cache[2] || (_cache[2] = [
          _createStaticVNode("<g id=\"圖層_2\" data-name=\"圖層 2\"><g id=\"本体\"><rect class=\"cls-1\" x=\"19\" y=\"167\" width=\"28\" height=\"28\"></rect><rect class=\"cls-1\" x=\"62\" y=\"181\" width=\"14\" height=\"14\"></rect><rect class=\"cls-1\" x=\"89\" y=\"163\" width=\"14\" height=\"14\"></rect><rect class=\"cls-1\" x=\"62\" y=\"63\" width=\"14\" height=\"14\"></rect><rect class=\"cls-1\" x=\"82\" y=\"85\" width=\"14\" height=\"14\"></rect><rect class=\"cls-1\" x=\"114\" y=\"66\" width=\"28\" height=\"28\"></rect><polygon class=\"cls-1\" points=\"112.54 153.5 33.5 153.5 33.5 106.5 155 106.5 155.5 106.5 159.37 106.5 159.46 207.5 159.5 254.36 159.5 254.46 144.5 254.47 144.5 254.37 144.5 238.5 116.5 238.5 116.5 254.4 116.5 254.5 112.63 254.5 112.63 254.4 3.54 254.5 3.5 207.64 112.59 207.54 112.54 153.5\"></polygon><polygon class=\"cls-1\" points=\"183.51 114.5 198.5 114.5 198.5 130.5 198.5 131.49 269.5 131.42 269.48 103.5 219.5 103.55 219.5 85.47 269.5 85.42 269.5 84.5 269.5 36.42 269.5 20.5 269.48 20.5 251.5 20.5 251.5 5.52 251.5 5.5 232.5 5.5 232.5 5.53 183.5 5.58 183.53 36.5 232.5 36.45 232.5 54.53 219.5 54.55 219.5 54.5 182.5 54.5 182.5 114.5 183.51 114.5\"></polygon><rect class=\"cls-1\" x=\"242\" y=\"153\" width=\"28\" height=\"28\"></rect><rect class=\"cls-1\" x=\"183\" y=\"240\" width=\"14\" height=\"14\"></rect><rect class=\"cls-1\" x=\"204\" y=\"166\" width=\"26\" height=\"14\"></rect><rect class=\"cls-1\" x=\"183\" y=\"190\" width=\"14\" height=\"41\"></rect><polygon class=\"cls-1\" points=\"269.5 189.5 269.5 230.62 245.62 254.5 203.5 254.5 203.5 189.5 269.5 189.5\"></polygon><rect class=\"cls-1\" width=\"8\" height=\"8\"></rect><rect class=\"cls-1\" x=\"266\" y=\"251\" width=\"8\" height=\"8\"></rect><polygon class=\"cls-1\" points=\"3.5 33.16 30.77 5.5 50.5 5.5 145.5 5.5 145.5 19.5 159.5 19.5 159.5 52.5 50.5 52.5 50.5 153.5 3.5 153.5 3.5 52.5 3.5 33.16\"></polygon></g></g>", 1)
        ])))
      ])
    ])
  ]))
}