import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "info-pan-set",
  style: {"padding":"0"}
}
const _hoisted_2 = {
  key: 0,
  class: "opt-item"
}
const _hoisted_3 = { class: "opt-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.type == 'group')
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          ((_ctx.chat.info.group_info.gOwner && _ctx.chat.info.group_info.gOwner === _ctx.runtimeData.loginInfo.uin) || (_ctx.chat.info.group_info.gAdmins && _ctx.chat.info.group_info.gAdmins.indexOf(_ctx.runtimeData.loginInfo.uin) >= 0))
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_font_awesome_icon, { icon: ['fas', 'pen'] }),
                _createElementVNode("div", null, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('chat_chat_info_group_name')), 1),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('chat_chat_info_group_name_tip')), 1)
                ]),
                _withDirectives(_createElementVNode("input", {
                  class: "ss-input",
                  style: {"width":"150px"},
                  type: "text",
                  onKeyup: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.setGroupName && _ctx.setGroupName(...args))),
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.runtimeData.chatInfo.show.name) = $event))
                }, null, 544), [
                  [_vModelText, _ctx.runtimeData.chatInfo.show.name]
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'note-sticky'] }),
            _createElementVNode("div", null, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('chat_chat_info_group_card')), 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('chat_chat_info_group_card_tip')), 1)
            ]),
            _withDirectives(_createElementVNode("input", {
              class: "ss-input",
              style: {"width":"150px"},
              type: "text",
              onKeyup: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.setGroupCard && _ctx.setGroupCard(...args))),
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.runtimeData.chatInfo.info.me_info.card) = $event))
            }, null, 544), [
              [_vModelText, _ctx.runtimeData.chatInfo.info.me_info.card]
            ])
          ]),
          _createElementVNode("button", {
            class: "ss-button",
            style: {"width":"calc(100% - 60px)","margin":"30px 30px 0 30px"},
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.leaveGroup()))
          }, _toDisplayString(_ctx.$t('exit_group')), 1)
        ], 64))
      : _createCommentVNode("", true)
  ]))
}