import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["data-raw", "id", "data-sender", "data-time"]
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 0,
  class: "message-space"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  class: "msg-text"
}
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["title", "alt", "src"]
const _hoisted_8 = ["alt", "src", "title"]
const _hoisted_9 = {
  key: 1,
  class: "msg-face-long"
}
const _hoisted_10 = ["src"]
const _hoisted_11 = {
  key: 6,
  class: "msg-unknown"
}
const _hoisted_12 = {
  key: 7,
  style: {"font-style":"italic","opacity":"0.7"}
}
const _hoisted_13 = ["data-id", "data-group"]
const _hoisted_14 = {
  key: 1,
  class: "download-bar",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_15 = ["stroke-dasharray"]
const _hoisted_16 = {
  key: 0,
  class: "file-view"
}
const _hoisted_17 = ["src"]
const _hoisted_18 = {
  key: 1,
  controls: "",
  muted: "",
  autoplay: ""
}
const _hoisted_19 = ["src", "type"]
const _hoisted_20 = {
  key: 2,
  class: "txt"
}
const _hoisted_21 = {
  key: 10,
  class: "msg-video"
}
const _hoisted_22 = {
  controls: "",
  muted: "",
  autoplay: ""
}
const _hoisted_23 = ["src"]
const _hoisted_24 = ["onClick"]
const _hoisted_25 = ["onClick"]
const _hoisted_26 = {
  key: 13,
  class: "msg-unknown"
}
const _hoisted_27 = ["id", "src"]
const _hoisted_28 = { class: "body" }
const _hoisted_29 = ["href"]
const _hoisted_30 = {
  key: 1,
  class: "sending"
}
const _hoisted_31 = { class: "emoji-like-body" }
const _hoisted_32 = ["src"]
const _hoisted_33 = { style: {"display":"none"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_CardMessage = _resolveComponent("CardMessage")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass('message' + (_ctx.type ? ' ' + _ctx.type : '') + (_ctx.data.revoke ? ' revoke' : '') + (_ctx.isMe ? ' me': '')),
    "data-raw": _ctx.getMsgRawTxt(_ctx.data.message),
    id: 'chat-' + _ctx.data.message_id,
    "data-sender": _ctx.data.sender.user_id,
    "data-time": _ctx.data.time,
    onMouseleave: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.hiddenUserInfo && _ctx.hiddenUserInfo(...args)))
  }, [
    _withDirectives(_createElementVNode("img", {
      name: "avatar",
      src: 'https://q1.qlogo.cn/g?b=qq&s=0&nk=' + _ctx.data.sender.user_id
    }, null, 8, _hoisted_2), [
      [_vShow, !_ctx.isMe || _ctx.type == 'merge']
    ]),
    (_ctx.isMe && _ctx.type != 'merge')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.isMe ? (_ctx.type == 'merge' ? 'message-body' : 'message-body me') : 'message-body')
    }, [
      (_ctx.data.sender.card || _ctx.data.sender.nickname)
        ? _withDirectives((_openBlock(), _createElementBlock("a", { key: 0 }, _toDisplayString(_ctx.data.sender.card ? _ctx.data.sender.card : _ctx.data.sender.nickname), 513)), [
            [_vShow, !_ctx.isMe || _ctx.type == 'merge']
          ])
        : _withDirectives((_openBlock(), _createElementBlock("a", { key: 1 }, _toDisplayString(_ctx.isMe ? _ctx.runtimeData.loginInfo.nickname : _ctx.runtimeData.chatInfo.show.name), 513)), [
            [_vShow, !_ctx.isMe || _ctx.type == 'merge']
          ]),
      _createElementVNode("div", null, [
        (!_ctx.hasCard())
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.data.message, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.View.isMsgInline(item.type) ? 'msg-inline' : ''),
                key: _ctx.data.message_id + '-m-' + index
              }, [
                (item.type === undefined)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4))
                  : (_ctx.isDebugMsg)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(item), 1))
                    : (item.type == 'text')
                      ? _withDirectives((_openBlock(), _createElementBlock("span", {
                          key: 2,
                          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.textClick && _ctx.textClick(...args))),
                          class: "msg-text",
                          innerHTML: _ctx.parseText(item.text)
                        }, null, 8, _hoisted_6)), [
                          [_vShow, item.text !== '']
                        ])
                      : (item.type == 'image')
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 3,
                            title: _ctx.$t('chat_view_pic'),
                            alt: _ctx.$t('chat_pic'),
                            onLoad: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.scrollButtom && _ctx.scrollButtom(...args))),
                            onError: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.imgLoadFail && _ctx.imgLoadFail(...args))),
                            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.imgClick(_ctx.data.message_id))),
                            class: _normalizeClass(_ctx.imgStyle(_ctx.data.message.length, index, item.asface)),
                            src: item.url
                          }, null, 42, _hoisted_7))
                        : (item.type == 'face')
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                              (_ctx.getFace(item.id))
                                ? (_openBlock(), _createElementBlock("img", {
                                    key: 0,
                                    alt: item.text,
                                    class: "msg-face",
                                    src: _ctx.getFace(item.id),
                                    title: item.text
                                  }, null, 8, _hoisted_8))
                                : (item.id == 394)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(15, (i) => {
                                        return _createElementVNode("span", {
                                          key: _ctx.data.message_id + '-l-' + i
                                        }, "🐲")
                                      }), 64))
                                    ]))
                                  : (_openBlock(), _createBlock(_component_font_awesome_icon, {
                                      key: 2,
                                      class: _normalizeClass('msg-face-svg' + (_ctx.isMe ? ' me': '')),
                                      icon: ['fas', 'face-grin-wide']
                                    }, null, 8, ["class"]))
                            ], 64))
                          : (item.type == 'mface' && item.url)
                            ? (_openBlock(), _createElementBlock("img", {
                                key: 5,
                                onLoad: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.scrollButtom && _ctx.scrollButtom(...args))),
                                onError: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.imgLoadFail && _ctx.imgLoadFail(...args))),
                                class: _normalizeClass(_ctx.imgStyle(_ctx.data.message.length, index, item.asface) + ' msg-mface'),
                                src: item.url
                              }, null, 42, _hoisted_10))
                            : (item.type == 'mface' && item.text)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(item.text), 1))
                              : (item.type == 'bface')
                                ? (_openBlock(), _createElementBlock("span", _hoisted_12, "[ " + _toDisplayString(_ctx.$t('chat_fun_menu_pic')) + "：" + _toDisplayString(item.text) + " ]", 1))
                                : (item.type == 'at')
                                  ? (_openBlock(), _createElementBlock("div", {
                                      key: 8,
                                      class: _normalizeClass(_ctx.getAtClass(item.qq))
                                    }, [
                                      _createElementVNode("a", {
                                        onMouseenter: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.showUserInfo && _ctx.showUserInfo(...args))),
                                        "data-id": item.qq,
                                        "data-group": _ctx.data.group_id
                                      }, _toDisplayString(_ctx.getAtName(item)), 41, _hoisted_13)
                                    ], 2))
                                  : (item.type == 'file')
                                    ? (_openBlock(), _createElementBlock("div", {
                                        key: 9,
                                        class: _normalizeClass('msg-file' + (_ctx.isMe ? ' me' : ''))
                                      }, [
                                        _createVNode(_component_font_awesome_icon, { icon: ['fas', 'file'] }),
                                        _createElementVNode("div", null, [
                                          _createElementVNode("div", null, [
                                            _createElementVNode("p", null, _toDisplayString(_ctx.loadFileBase(item, item.name, _ctx.data.message_id)), 1),
                                            _createElementVNode("a", null, "（" + _toDisplayString(_ctx.getSizeFromBytes(item.size)) + "）", 1)
                                          ]),
                                          _createElementVNode("i", null, _toDisplayString(item.md5), 1)
                                        ]),
                                        _createElementVNode("div", null, [
                                          (item.downloadingPercentage === undefined)
                                            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                                                key: 0,
                                                onClick: ($event: any) => (_ctx.downloadFile(item, _ctx.data.message_id)),
                                                icon: ['fas', 'angle-down']
                                              }, null, 8, ["onClick"]))
                                            : _createCommentVNode("", true),
                                          (item.downloadingPercentage !== undefined)
                                            ? (_openBlock(), _createElementBlock("svg", _hoisted_14, [
                                                _cache[9] || (_cache[9] = _createElementVNode("circle", {
                                                  cx: "50%",
                                                  cy: "50%",
                                                  r: "40%",
                                                  "stroke-width": "15%",
                                                  fill: "none",
                                                  "stroke-linecap": "round"
                                                }, null, -1)),
                                                _createElementVNode("circle", {
                                                  cx: "50%",
                                                  cy: "50%",
                                                  r: "40%",
                                                  "stroke-width": "15%",
                                                  fill: "none",
                                                  "stroke-dasharray": item.downloadingPercentage === undefined ?
                                    '0,10000' : `${Math.floor(2 * Math.PI * 25) * item.downloadingPercentage / 100},10000`
                                                }, null, 8, _hoisted_15)
                                              ]))
                                            : _createCommentVNode("", true)
                                        ]),
                                        (_ctx.data.fileView && Object.keys(_ctx.data.fileView).length > 0)
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                              (['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(_ctx.data.fileView.ext))
                                                ? (_openBlock(), _createElementBlock("img", {
                                                    key: 0,
                                                    src: _ctx.data.fileView.url
                                                  }, null, 8, _hoisted_17))
                                                : _createCommentVNode("", true),
                                              (['mp4', 'avi', 'mkv', 'flv'].includes(_ctx.data.fileView.ext))
                                                ? (_openBlock(), _createElementBlock("video", _hoisted_18, [
                                                    _createElementVNode("source", {
                                                      src: _ctx.data.fileView.url,
                                                      type: 'video/' + _ctx.data.fileView.ext
                                                    }, null, 8, _hoisted_19),
                                                    _cache[10] || (_cache[10] = _createTextVNode(" 现在还有不支持 video tag 的浏览器吗？ "))
                                                  ]))
                                                : _createCommentVNode("", true),
                                              (['txt', 'md'].includes(_ctx.data.fileView.ext) && item.size < 2000000)
                                                ? (_openBlock(), _createElementBlock("span", _hoisted_20, [
                                                    _createElementVNode("a", null, "> " + _toDisplayString(item.name) + " - " + _toDisplayString(_ctx.$t('chat_view_file_viewer')), 1),
                                                    _createTextVNode(" " + _toDisplayString(_ctx.getTxtUrl(_ctx.data.fileView.url, _ctx.data.message_id)) + _toDisplayString(_ctx.data.fileView.txt), 1)
                                                  ]))
                                                : _createCommentVNode("", true)
                                            ]))
                                          : _createCommentVNode("", true)
                                      ], 2))
                                    : (item.type == 'video')
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                                          _createElementVNode("video", _hoisted_22, [
                                            _createElementVNode("source", {
                                              src: item.url,
                                              type: "video/mp4"
                                            }, null, 8, _hoisted_23),
                                            _cache[11] || (_cache[11] = _createTextVNode(" 现在还有不支持 video tag 的浏览器吗？ "))
                                          ])
                                        ]))
                                      : (item.type == 'forward')
                                        ? (_openBlock(), _createElementBlock("span", {
                                            key: 11,
                                            class: "msg-unknown",
                                            style: {"cursor":"pointer"},
                                            onClick: ($event: any) => (_ctx.View.getForwardMsg(item.id))
                                          }, _toDisplayString(_ctx.$t('chat_show_forward')), 9, _hoisted_24))
                                        : (item.type == 'reply')
                                          ? (_openBlock(), _createElementBlock("div", {
                                              key: 12,
                                              onClick: ($event: any) => (_ctx.scrollToMsg(item.id)),
                                              class: _normalizeClass(_ctx.isMe ? (_ctx.type == 'merge' ? 'msg-replay' : 'msg-replay me') : 'msg-replay')
                                            }, [
                                              _createVNode(_component_font_awesome_icon, { icon: ['fas', 'reply'] }),
                                              _createElementVNode("a", {
                                                class: _normalizeClass(_ctx.getRepMsg(item.id) ? '' : 'msg-unknown'),
                                                style: {"cursor":"pointer"}
                                              }, _toDisplayString(_ctx.getRepMsg(item.id) ?? _ctx.$t('chat_jump_reply')), 3)
                                            ], 10, _hoisted_25))
                                          : (_openBlock(), _createElementBlock("span", _hoisted_26, _toDisplayString('( ' + _ctx.$t('chat_unsupported_msg') + ': ' + item.type + ' )'), 1))
              ], 2))
            }), 128))
          : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.data.message, (item, index) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: _ctx.data.message_id + '-m-' + index
              }, [
                (item.type == 'xml' || item.type == 'json')
                  ? (_openBlock(), _createBlock(_component_CardMessage, {
                      key: 0,
                      item: item,
                      id: _ctx.data.message_id
                    }, null, 8, ["item", "id"]))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128)),
        (_ctx.pageViewInfo !== undefined && Object.keys(_ctx.pageViewInfo).length > 0)
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: _normalizeClass('msg-link-view ' + _ctx.linkViewStyle)
            }, [
              _createElementVNode("div", {
                class: _normalizeClass('bar' + (_ctx.isMe ? ' me' : ''))
              }, null, 2),
              _createElementVNode("div", null, [
                (_ctx.pageViewInfo.img !== undefined)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      id: _ctx.data.message_id + '-linkview-img',
                      onLoad: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.linkViewPicFin && _ctx.linkViewPicFin(...args))),
                      alt: "预览图片",
                      title: "查看图片",
                      src: _ctx.pageViewInfo.img
                    }, null, 40, _hoisted_27))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_28, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.pageViewInfo.site), 1),
                  _createElementVNode("span", {
                    href: _ctx.pageViewInfo.url
                  }, _toDisplayString(_ctx.pageViewInfo.title), 9, _hoisted_29),
                  _createElementVNode("span", null, _toDisplayString(_ctx.pageViewInfo.desc), 1)
                ])
              ])
            ], 2))
          : _createCommentVNode("", true)
      ])
    ], 2),
    (_ctx.data.fake_msg == true)
      ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
          _createVNode(_component_font_awesome_icon, { icon: ['fas', 'spinner'] })
        ]))
      : _createCommentVNode("", true),
    (_ctx.data.emoji_like)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass('emoji-like' + (_ctx.isMe ? ' me' : ''))
        }, [
          _createElementVNode("div", _hoisted_31, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.emoji_like, (info) => {
              return _withDirectives((_openBlock(), _createElementBlock("div", {
                key: 'respond-' + _ctx.data.message_id + '-' + info.emoji_id
              }, [
                _createElementVNode("img", {
                  loading: "lazy",
                  src: (_ctx.getFace(info.emoji_id) as any)
                }, null, 8, _hoisted_32),
                _createElementVNode("span", null, _toDisplayString(info.count), 1)
              ])), [
                [_vShow, _ctx.getFace(info.emoji_id) != false]
              ])
            }), 128))
          ])
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("code", _hoisted_33, _toDisplayString(_ctx.data.raw_message), 1)
  ], 42, _hoisted_1))
}