import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "opt-page" }
const _hoisted_2 = { class: "ss-card" }
const _hoisted_3 = { class: "opt-item" }
const _hoisted_4 = { class: "ss-switch" }
const _hoisted_5 = {
  key: 0,
  class: "opt-item"
}
const _hoisted_6 = { class: "ss-switch" }
const _hoisted_7 = { class: "ss-card" }
const _hoisted_8 = { class: "opt-item" }
const _hoisted_9 = { class: "ss-switch" }
const _hoisted_10 = { class: "opt-item" }
const _hoisted_11 = {
  key: 0,
  class: "ss-switch"
}
const _hoisted_12 = { class: "opt-item" }
const _hoisted_13 = { class: "ss-switch" }
const _hoisted_14 = { class: "opt-item" }
const _hoisted_15 = { class: "ss-switch" }
const _hoisted_16 = { class: "opt-item" }
const _hoisted_17 = { class: "opt-item" }
const _hoisted_18 = { class: "ss-switch" }
const _hoisted_19 = { class: "ss-card" }
const _hoisted_20 = { class: "ss-switch" }
const _hoisted_21 = {
  key: 0,
  class: "tip"
}
const _hoisted_22 = { class: "ga-share" }
const _hoisted_23 = ["href"]
const _hoisted_24 = {
  key: 1,
  class: "opt-item"
}
const _hoisted_25 = { class: "ss-switch" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("header", null, _toDisplayString(_ctx.$t('option_fun_notice')), 1),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_font_awesome_icon, { icon: ['fas', 'volume-xmark'] }),
        _createElementVNode("div", null, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_dev_notice_close')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_dev_notice_close_tip')), 1)
        ]),
        _createElementVNode("label", _hoisted_4, [
          _withDirectives(_createElementVNode("input", {
            type: "checkbox",
            onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args))),
            name: "close_notice",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.runtimeData.sysConfig.close_notice) = $event))
          }, null, 544), [
            [_vModelCheckbox, _ctx.runtimeData.sysConfig.close_notice]
          ]),
          _cache[20] || (_cache[20] = _createElementVNode("div", null, [
            _createElementVNode("div")
          ], -1))
        ])
      ]),
      (!_ctx.runtimeData.sysConfig.close_notice)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'bolt'] }),
            _createElementVNode("div", null, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_dev_notice_all')), 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_dev_notice_all_tip')), 1)
            ]),
            _createElementVNode("label", _hoisted_6, [
              _withDirectives(_createElementVNode("input", {
                type: "checkbox",
                onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args))),
                name: "notice_all",
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.runtimeData.sysConfig.notice_all) = $event))
              }, null, 544), [
                [_vModelCheckbox, _ctx.runtimeData.sysConfig.notice_all]
              ]),
              _cache[21] || (_cache[21] = _createElementVNode("div", null, [
                _createElementVNode("div")
              ], -1))
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("header", null, _toDisplayString(_ctx.$t('option_fun_chat')), 1),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_font_awesome_icon, { icon: ['fas', 'ban'] }),
        _createElementVNode("div", null, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_dev_send_reget')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_dev_send_reget_tip')), 1)
        ]),
        _createElementVNode("label", _hoisted_9, [
          _withDirectives(_createElementVNode("input", {
            type: "checkbox",
            onChange: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args))),
            name: "send_reget",
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.runtimeData.sysConfig.send_reget) = $event))
          }, null, 544), [
            [_vModelCheckbox, _ctx.runtimeData.sysConfig.send_reget]
          ]),
          _cache[22] || (_cache[22] = _createElementVNode("div", null, [
            _createElementVNode("div")
          ], -1))
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_font_awesome_icon, { icon: ['fas', 'box-archive'] }),
        _createElementVNode("div", null, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_dev_chat_nd')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.ndt === 0 ? _ctx.$t('option_dev_chat_nd_tip') : _ctx.$t('option_dev_chat_nd_tip_1')), 1)
        ]),
        (_ctx.ndt < 3)
          ? (_openBlock(), _createElementBlock("label", _hoisted_11, [
              _withDirectives(_createElementVNode("input", {
                type: "checkbox",
                onChange: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.msgND && _ctx.msgND(...args))),
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.ndv) = $event))
              }, null, 544), [
                [_vModelCheckbox, _ctx.ndv]
              ]),
              _cache[23] || (_cache[23] = _createElementVNode("div", null, [
                _createElementVNode("div")
              ], -1))
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_font_awesome_icon, { icon: ['fas', 'window-maximize'] }),
        _createElementVNode("div", null, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_dev_chat_pic_pan')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_dev_chat_pic_pan_tip')), 1)
        ]),
        _createElementVNode("label", _hoisted_13, [
          _withDirectives(_createElementVNode("input", {
            type: "checkbox",
            onChange: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args))),
            name: "close_chat_pic_pan",
            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.runtimeData.sysConfig.close_chat_pic_pan) = $event))
          }, null, 544), [
            [_vModelCheckbox, _ctx.runtimeData.sysConfig.close_chat_pic_pan]
          ]),
          _cache[24] || (_cache[24] = _createElementVNode("div", null, [
            _createElementVNode("div")
          ], -1))
        ])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createVNode(_component_font_awesome_icon, { icon: ['fas', 'face-laugh-squint'] }),
        _createElementVNode("div", null, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_dev_chat_respond')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_dev_chat_respond_tip')), 1)
        ]),
        _createElementVNode("label", _hoisted_15, [
          _withDirectives(_createElementVNode("input", {
            type: "checkbox",
            onChange: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args))),
            name: "close_respond",
            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.runtimeData.sysConfig.close_respond) = $event))
          }, null, 544), [
            [_vModelCheckbox, _ctx.runtimeData.sysConfig.close_respond]
          ]),
          _cache[25] || (_cache[25] = _createElementVNode("div", null, [
            _createElementVNode("div")
          ], -1))
        ])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createVNode(_component_font_awesome_icon, { icon: ['fas', 'fish-fins'] }),
        _createElementVNode("div", null, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_fun_taill')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_fun_taill_tip')), 1)
        ]),
        _withDirectives(_createElementVNode("input", {
          class: "ss-input",
          style: {"width":"150px"},
          type: "text",
          name: "msg_taill",
          onKeyup: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args))),
          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.runtimeData.sysConfig.msg_taill) = $event))
        }, null, 544), [
          [_vModelText, _ctx.runtimeData.sysConfig.msg_taill]
        ])
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createVNode(_component_font_awesome_icon, { icon: ['fas', 'keyboard'] }),
        _createElementVNode("div", null, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_dev_chat_use_breakline')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_dev_chat_use_breakline_tip')), 1)
        ]),
        _createElementVNode("label", _hoisted_18, [
          _withDirectives(_createElementVNode("input", {
            type: "checkbox",
            onChange: _cache[14] || (_cache[14] = ($event: any) => {_ctx.breakLineTip($event);_ctx.save($event)}),
            name: "use_breakline",
            "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.runtimeData.sysConfig.use_breakline) = $event))
          }, null, 544), [
            [_vModelCheckbox, _ctx.runtimeData.sysConfig.use_breakline]
          ]),
          _cache[26] || (_cache[26] = _createElementVNode("div", null, [
            _createElementVNode("div")
          ], -1))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_19, [
      _createElementVNode("header", null, _toDisplayString(_ctx.$t('option_fun_ga')), 1),
      _createElementVNode("div", {
        class: "opt-item",
        style: _normalizeStyle(_ctx.runtimeData.sysConfig.close_ga !== true ? 'background: var(--color-card-1);' : '')
      }, [
        _createVNode(_component_font_awesome_icon, { icon: ['fas', 'cloud'] }),
        _createElementVNode("div", null, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_fun_ga_turn')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_fun_ga_turn_tip')), 1)
        ]),
        _createElementVNode("label", _hoisted_20, [
          _withDirectives(_createElementVNode("input", {
            type: "checkbox",
            onChange: _cache[16] || (_cache[16] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args))),
            name: "close_ga",
            "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.runtimeData.sysConfig.close_ga) = $event))
          }, null, 544), [
            [_vModelCheckbox, _ctx.runtimeData.sysConfig.close_ga]
          ]),
          _cache[27] || (_cache[27] = _createElementVNode("div", { style: {"background":"var(--color-card-2)"} }, [
            _createElementVNode("div")
          ], -1))
        ])
      ], 4),
      (_ctx.runtimeData.sysConfig.close_ga !== true)
        ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
            _createTextVNode(_toDisplayString(_ctx.$t('option_fun_ga_tip')) + " ", 1),
            _createElementVNode("div", _hoisted_22, [
              _cache[28] || (_cache[28] = _createElementVNode("svg", {
                xmlns: "http://www.w3.org/2000/svg",
                width: "20",
                height: "20",
                viewBox: "0 0 428 389.11"
              }, [
                _createElementVNode("circle", {
                  cx: "214.15",
                  cy: "181",
                  r: "171",
                  fill: "none",
                  stroke: "currentColor",
                  "stroke-miterlimit": "10",
                  "stroke-width": "20"
                }),
                _createElementVNode("path", { d: "M413 134.11H15.29a15 15 0 0 0-15 15v15.3C.12 168 0 171.52 0 175.11c0 118.19 95.81 214 214 214 116.4 0 211.1-92.94 213.93-208.67 0-.44.07-.88.07-1.33v-30a15 15 0 0 0-15-15Z" })
              ], -1)),
              _createElementVNode("a", {
                href: _ctx.shareLink,
                target: "_blank"
              }, _toDisplayString(_ctx.$t('name')) + " " + _toDisplayString(_ctx.$t('option_fun_ga_tip_1')), 9, _hoisted_23)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.runtimeData.sysConfig.close_ga !== true)
        ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'dice'] }),
            _createElementVNode("div", null, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_fun_ga_bot')), 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_fun_ga_bot_tip')), 1)
            ]),
            _createElementVNode("label", _hoisted_25, [
              _withDirectives(_createElementVNode("input", {
                type: "checkbox",
                onChange: _cache[18] || (_cache[18] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args))),
                name: "open_ga_bot",
                "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.runtimeData.sysConfig.open_ga_bot) = $event))
              }, null, 544), [
                [_vModelCheckbox, _ctx.runtimeData.sysConfig.open_ga_bot]
              ]),
              _cache[29] || (_cache[29] = _createElementVNode("div", null, [
                _createElementVNode("div")
              ], -1))
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}