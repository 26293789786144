import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, TransitionGroup as _TransitionGroup, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, vModelText as _vModelText, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "info" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "info" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "more" }
const _hoisted_7 = {
  key: 0,
  class: "note note-nomsg"
}
const _hoisted_8 = { class: "ss-card" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = {
  class: "more",
  id: "send-more"
}
const _hoisted_11 = { class: "ss-card jin-pan" }
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "context" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = ["src"]
const _hoisted_16 = ["src"]
const _hoisted_17 = { class: "jin-pan-load" }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = ["src"]
const _hoisted_20 = {
  key: 0,
  class: "emp"
}
const _hoisted_21 = ["title"]
const _hoisted_22 = ["title"]
const _hoisted_23 = ["title"]
const _hoisted_24 = ["title"]
const _hoisted_25 = ["title"]
const _hoisted_26 = ["disabled", "placeholder"]
const _hoisted_27 = ["disabled"]
const _hoisted_28 = { class: "ss-card" }
const _hoisted_29 = { class: "mumber-info" }
const _hoisted_30 = ["src"]
const _hoisted_31 = { name: "id" }
const _hoisted_32 = { key: 0 }
const _hoisted_33 = { key: 0 }
const _hoisted_34 = ["onClick", "src"]
const _hoisted_35 = { class: "img-sender" }
const _hoisted_36 = { class: "card ss-card" }
const _hoisted_37 = { class: "hander" }
const _hoisted_38 = { class: "imgs" }
const _hoisted_39 = ["onClick"]
const _hoisted_40 = ["src"]
const _hoisted_41 = { class: "sender" }
const _hoisted_42 = ["disabled"]
const _hoisted_43 = {
  key: 0,
  class: "forward-pan"
}
const _hoisted_44 = { class: "ss-card card" }
const _hoisted_45 = ["placeholder"]
const _hoisted_46 = ["onClick"]
const _hoisted_47 = ["title", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_NoticeBody = _resolveComponent("NoticeBody")!
  const _component_MsgBody = _resolveComponent("MsgBody")!
  const _component_FacePan = _resolveComponent("FacePan")!
  const _component_Info = _resolveComponent("Info")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass('chat-pan' + (_ctx.runtimeData.tags.openSideBar ? ' open': '') + (_ctx.runtimeData.sysConfig.opt_no_window ? ' withBar': '')),
    style: _normalizeStyle(`background-image: url(${_ctx.runtimeData.sysConfig.chat_background});` + (_ctx.Option.get('fs_adaptation') > 0 ? ('--append-fs-adaptation:' + _ctx.Option.get('fs_adaptation') + 'px') : '--append-fs-adaptation:0px')),
    id: "chat-pan"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_font_awesome_icon, {
        onClick: _ctx.openLeftBar,
        icon: ['fas', 'bars-staggered']
      }, null, 8, ["onClick"]),
      _createElementVNode("img", {
        src: _ctx.chat.show.avatar
      }, null, 8, _hoisted_2),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", null, _toDisplayString(_ctx.chat.show.name), 1),
        (_ctx.chat.show.temp)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t('chat_temp_from', { group: _ctx.chat.show.temp })), 1))
          : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.list[_ctx.list.length - 1] ? _ctx.$t('chat_last_msg', {
                            time: Intl.DateTimeFormat(_ctx.trueLang,
                                { hour: "numeric", minute: "numeric", second: "numeric" }).format(new Date(_ctx.list[_ctx.list.length - 1].time *
                                    1000))
                        }) : _ctx.$t('chat_no_msg')), 1))
      ]),
      _cache[45] || (_cache[45] = _createElementVNode("div", { class: "space" }, null, -1)),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_font_awesome_icon, {
          onClick: _ctx.openChatInfoPan,
          icon: ['fas', 'ellipsis-vertical']
        }, null, 8, ["onClick"])
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass('loading' + (_ctx.tags.nowGetHistroy && _ctx.runtimeData.tags.canLoadHistory ? ' show': ''))
    }, [
      _createVNode(_component_font_awesome_icon, { icon: ['fas', 'spinner'] }),
      _createElementVNode("span", null, _toDisplayString(_ctx.$t('loading')), 1)
    ], 2),
    _createElementVNode("div", {
      class: "chat",
      onScroll: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.chatScroll && _ctx.chatScroll(...args))),
      id: "msgPan",
      style: {"scroll-behavior":"smooth"}
    }, [
      (!_ctx.runtimeData.tags.canLoadHistory)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _cache[46] || (_cache[46] = _createElementVNode("hr", null, null, -1)),
            _createElementVNode("a", null, _toDisplayString(_ctx.$t('chat_no_more_msg')), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.tags.nowGetHistroy)
        ? (_openBlock(), _createBlock(_component_NoticeBody, {
            key: 1,
            data: {sub_type: 'time', time: _ctx.list[0].time}
          }, null, 8, ["data"]))
        : _createCommentVNode("", true),
      _createVNode(_TransitionGroup, {
        name: "msglist",
        tag: "div"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (msg, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              (_ctx.isShowTime((_ctx.list[index - 1] ? _ctx.list[index - 1].time : undefined), msg.time))
                ? (_openBlock(), _createBlock(_component_NoticeBody, {
                    key: 'notice-time-' + index,
                    data: {sub_type: 'time', time: msg.time}
                  }, null, 8, ["data"]))
                : _createCommentVNode("", true),
              ((msg.post_type === 'message' || msg.post_type === 'message_sent') && msg.message.length > 0)
                ? (_openBlock(), _createBlock(_component_MsgBody, {
                    key: msg.message_id,
                    data: msg,
                    onScrollToMsg: _ctx.scrollToMsg,
                    onScrollButtom: _ctx.imgLoadedScroll,
                    onContextmenu: _withModifiers(($event: any) => (_ctx.showMsgMeun($event, msg)), ["prevent"]),
                    onTouchstart: ($event: any) => (_ctx.msgStartMove($event, msg)),
                    onTouchmove: _ctx.msgOnMove,
                    onTouchend: ($event: any) => (_ctx.msgMoveEnd($event, msg))
                  }, null, 8, ["data", "onScrollToMsg", "onScrollButtom", "onContextmenu", "onTouchstart", "onTouchmove", "onTouchend"]))
                : _createCommentVNode("", true),
              (msg.post_type === 'notice')
                ? (_openBlock(), _createBlock(_component_NoticeBody, {
                    id: _ctx.uuid(),
                    key: 'notice-' + index,
                    data: msg
                  }, null, 8, ["id", "data"]))
                : _createCommentVNode("", true)
            ], 64))
          }), 256))
        ]),
        _: 1
      })
    ], 32),
    _withDirectives(_createElementVNode("div", {
      class: "new-msg",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.scrollBottom(true)))
    }, [
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_font_awesome_icon, { icon: ['fas', 'comment'] }),
        (_ctx.NewMsgNum > 0)
          ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.NewMsgNum), 1))
          : _createCommentVNode("", true)
      ])
    ], 512), [
      [_vShow, _ctx.tags.showBottomButton]
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", null, [
        _createElementVNode("div", null, [
          _createVNode(_Transition, { name: "pan" }, {
            default: _withCtx(() => [
              _withDirectives(_createVNode(_component_FacePan, { onAddSpecialMsg: _ctx.addSpecialMsg }, null, 8, ["onAddSpecialMsg"]), [
                [_vShow, _ctx.details[1].open]
              ])
            ]),
            _: 1
          }),
          _createVNode(_Transition, { name: "pan" }, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", null, [
                  _createVNode(_component_font_awesome_icon, { icon: ['fas', 'message'] }),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('chat_fun_menu_jin')), 1),
                  _createVNode(_component_font_awesome_icon, {
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.details[2].open = !_ctx.details[2].open)),
                    icon: ['fas', 'xmark']
                  })
                ]),
                _createElementVNode("div", {
                  class: "jin-pan-body",
                  onScroll: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.jinScroll && _ctx.jinScroll(...args)))
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.runtimeData.chatInfo.info.jin_info.list, (item, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: 'jin-' + index
                    }, [
                      _createElementVNode("div", null, [
                        _createElementVNode("img", {
                          src: `https://q1.qlogo.cn/g?b=qq&s=0&nk=${item.sender_uin}`
                        }, null, 8, _hoisted_12),
                        _createElementVNode("div", null, [
                          _createElementVNode("a", null, _toDisplayString(item.sender_nick), 1),
                          _createElementVNode("span", null, _toDisplayString(Intl.DateTimeFormat(_ctx.trueLang,
                                                { hour: "numeric", minute: "numeric" })
                                                .format(new Date(item.sender_time * 1000))) + " " + _toDisplayString(_ctx.$t('chat_send')), 1)
                        ]),
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('chat_fun_menu_jin_sender',
                                         { time: Intl.DateTimeFormat(_ctx.trueLang,
                                                    { hour: "numeric", minute: "numeric" })
                                                    .format(new Date(item.add_digest_time * 1000)),name: item.add_digest_nick })), 1)
                      ]),
                      _createElementVNode("div", _hoisted_13, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.msg_content, (context, indexc) => {
                          return (_openBlock(), _createElementBlock(_Fragment, {
                            key: 'jinc-' + index + '-' + indexc
                          }, [
                            (context.msg_type === 1)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(context.text), 1))
                              : _createCommentVNode("", true),
                            (context.msg_type === 2)
                              ? (_openBlock(), _createElementBlock("img", {
                                  key: 1,
                                  class: "face",
                                  src: require('./../assets/img/qq-face/gif/s' + context.face_index + '.gif')
                                }, null, 8, _hoisted_15))
                              : _createCommentVNode("", true),
                            (context.msg_type === 3)
                              ? (_openBlock(), _createElementBlock("img", {
                                  key: 2,
                                  src: context.image_url
                                }, null, 8, _hoisted_16))
                              : _createCommentVNode("", true)
                          ], 64))
                        }), 128))
                      ])
                    ]))
                  }), 128)),
                  _withDirectives(_createElementVNode("div", _hoisted_17, [
                    _createVNode(_component_font_awesome_icon, { icon: ['fas', 'spinner'] })
                  ], 512), [
                    [_vShow, _ctx.tags.isJinLoading]
                  ])
                ], 32)
              ], 512), [
                [_vShow, _ctx.details[2].open && _ctx.runtimeData.chatInfo.info.jin_info.list.length > 0]
              ])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.tags.isReply ? 'replay-tag show' : 'replay-tag')
        }, [
          _createVNode(_component_font_awesome_icon, { icon: ['fas', 'reply'] }),
          _createElementVNode("span", null, _toDisplayString(_ctx.selectedMsg === null ? '' : (_ctx.selectedMsg.sender.nickname + ': ' + _ctx.fun.getMsgRawTxt(_ctx.selectedMsg.message))), 1),
          _createElementVNode("div", {
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.cancelReply && _ctx.cancelReply(...args)))
          }, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'xmark'] })
          ])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.atFindList != null ? 'at-tag show' : 'at-tag'),
          contenteditable: "true",
          onBlur: _cache[5] || (_cache[5] = ($event: any) => (_ctx.choiceAt(undefined)))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.atFindList != null ? _ctx.atFindList : []), (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: 'atFind-' + item.user_id,
              onClick: ($event: any) => (_ctx.choiceAt(item.user_id))
            }, [
              _createElementVNode("img", {
                src: 'https://q1.qlogo.cn/g?b=qq&s=0&nk=' + item.user_id
              }, null, 8, _hoisted_19),
              _createElementVNode("span", null, _toDisplayString(item.card != '' && item.card != null ? item.card : item.nickname), 1),
              _createElementVNode("a", null, _toDisplayString(item.user_id), 1)
            ], 8, _hoisted_18))
          }), 128)),
          (_ctx.atFindList?.length == 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('chat_fun_at_find_emp')), 1)
              ]))
            : _createCommentVNode("", true)
        ], 34),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.tags.showMoreDetail ? 'more-detail show' : 'more-detail')
        }, [
          _createElementVNode("div", {
            title: _ctx.$t('chat_fun_menu_pic'),
            onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.runSelectImg && _ctx.runSelectImg(...args)))
          }, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'image'] }),
            _createElementVNode("input", {
              id: "choice-pic",
              type: "file",
              style: {"display":"none"},
              onChange: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.selectImg && _ctx.selectImg(...args)))
            }, null, 32)
          ], 8, _hoisted_21),
          _createElementVNode("div", {
            title: _ctx.$t('chat_fun_menu_file'),
            onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.runSelectFile && _ctx.runSelectFile(...args)))
          }, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'folder'] }),
            _createElementVNode("input", {
              id: "choice-file",
              type: "file",
              style: {"display":"none"},
              onChange: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.selectFile && _ctx.selectFile(...args)))
            }, null, 32)
          ], 8, _hoisted_22),
          _createElementVNode("div", {
            title: _ctx.$t('chat_fun_menu_face'),
            onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.details[1].open = !_ctx.details[1].open, _ctx.tags.showMoreDetail = false))
          }, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'face-laugh'] })
          ], 8, _hoisted_23),
          (_ctx.chat.show.type === 'user')
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                title: _ctx.$t('chat_fun_menu_poke'),
                onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.sendPoke && _ctx.sendPoke(...args)))
              }, [
                _createVNode(_component_font_awesome_icon, { icon: ['fas', 'bomb'] })
              ], 8, _hoisted_24))
            : _createCommentVNode("", true),
          (_ctx.chat.show.type === 'group')
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                title: _ctx.$t('chat_fun_menu_jin'),
                onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.showJin && _ctx.showJin(...args)))
              }, [
                _createVNode(_component_font_awesome_icon, { icon: ['fas', 'star'] })
              ], 8, _hoisted_25))
            : _createCommentVNode("", true)
        ], 2)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("div", {
          onClick: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.moreFunClick && _ctx.moreFunClick(...args)))
        }, [
          _createVNode(_component_font_awesome_icon, { icon: ['fas', 'plus'] })
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("form", {
            onSubmit: _cache[23] || (_cache[23] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.mainSubmit && _ctx.mainSubmit(...args)), ["prevent"]))
          }, [
            (!_ctx.Option.get('use_breakline'))
              ? _withDirectives((_openBlock(), _createElementBlock("input", {
                  key: 0,
                  id: "main-input",
                  type: "text",
                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.msg) = $event)),
                  autocomplete: "off",
                  disabled: _ctx.runtimeData.tags.openSideBar || _ctx.chat.info.me_info.shut_up_timestamp > 0,
                  placeholder: _ctx.chat.info.me_info.shut_up_timestamp > 0 ? _ctx.$t('chat_send_msg_watermark_ban', {
                            time: Intl.DateTimeFormat(_ctx.trueLang, _ctx.getTimeConfig(new Date(_ctx.chat.info.me_info.shut_up_timestamp * 1000))).format(new Date(_ctx.chat.info.me_info.shut_up_timestamp * 1000)) }) : '',
                  onPaste: _cache[15] || (_cache[15] = 
//@ts-ignore
(...args) => (_ctx.addImg && _ctx.addImg(...args))),
                  onKeyup: _cache[16] || (_cache[16] = 
//@ts-ignore
(...args) => (_ctx.mainKeyUp && _ctx.mainKeyUp(...args))),
                  onClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.selectSQIn()))
                }, null, 40, _hoisted_26)), [
                  [_vModelText, _ctx.msg]
                ])
              : _withDirectives((_openBlock(), _createElementBlock("textarea", {
                  key: 1,
                  id: "main-input",
                  type: "text",
                  "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.msg) = $event)),
                  disabled: _ctx.runtimeData.tags.openSideBar,
                  onPaste: _cache[19] || (_cache[19] = 
//@ts-ignore
(...args) => (_ctx.addImg && _ctx.addImg(...args))),
                  onKeydown: _cache[20] || (_cache[20] = 
//@ts-ignore
(...args) => (_ctx.mainKey && _ctx.mainKey(...args))),
                  onKeyup: _cache[21] || (_cache[21] = 
//@ts-ignore
(...args) => (_ctx.mainKeyUp && _ctx.mainKeyUp(...args))),
                  onClick: _cache[22] || (_cache[22] = ($event: any) => (_ctx.selectSQIn()))
                }, "\n                        ", 40, _hoisted_27)), [
                  [_vModelText, _ctx.msg]
                ])
          ], 32),
          _createElementVNode("div", {
            onClick: _cache[24] || (_cache[24] = 
//@ts-ignore
(...args) => (_ctx.sendMsg && _ctx.sendMsg(...args)))
          }, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'angle-right'] })
          ])
        ])
      ]),
      _cache[47] || (_cache[47] = _createElementVNode("div", null, null, -1))
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.mergeList != undefined ? 'merge-pan show' : 'merge-pan')
    }, [
      _createElementVNode("div", {
        onClick: _cache[25] || (_cache[25] = 
//@ts-ignore
(...args) => (_ctx.closeMergeMsg && _ctx.closeMergeMsg(...args)))
      }),
      _createElementVNode("div", _hoisted_28, [
        _createElementVNode("div", null, [
          _createVNode(_component_font_awesome_icon, {
            style: {"margin-top":"5px"},
            icon: ['fas', 'message']
          }),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('chat_merge_msg')), 1),
          _createVNode(_component_font_awesome_icon, {
            onClick: _ctx.closeMergeMsg,
            icon: ['fas', 'xmark']
          }, null, 8, ["onClick"])
        ]),
        _createElementVNode("div", {
          class: _normalizeClass('loading' + ((_ctx.mergeList && _ctx.mergeList.length == 0) ? ' show': ''))
        }, [
          _createVNode(_component_font_awesome_icon, { icon: ['fas', 'spinner'] }),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('loading')), 1)
        ], 2),
        _createElementVNode("div", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mergeList, (msg, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: 'merge-' + index
            }, [
              (_ctx.isShowTime((_ctx.mergeList[index - 1] ? _ctx.mergeList[index - 1].time : undefined), msg.time, index == 0))
                ? (_openBlock(), _createBlock(_component_NoticeBody, {
                    key: 'notice-time-' + index,
                    id: _ctx.uuid(),
                    data: {sub_type: 'time', time: msg.time}
                  }, null, 8, ["id", "data"]))
                : _createCommentVNode("", true),
              _createVNode(_component_MsgBody, {
                data: msg,
                type: 'merge'
              }, null, 8, ["data"])
            ], 64))
          }), 128))
        ])
      ])
    ], 2),
    _createElementVNode("div", _hoisted_29, [
      (Object.keys(_ctx.mumberInfo).length > 0 && _ctx.mumberInfo.error === undefined)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "ss-card",
            style: _normalizeStyle(_ctx.getPopPost())
          }, [
            _createElementVNode("img", {
              src: 'https://q1.qlogo.cn/g?b=qq&s=0&nk=' + _ctx.mumberInfo.user_id
            }, null, 8, _hoisted_30),
            _createElementVNode("div", null, [
              _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.mumberInfo.user_id), 1),
              _createElementVNode("div", null, [
                _createElementVNode("a", null, _toDisplayString(_ctx.mumberInfo.card == '' ? _ctx.mumberInfo.nickname : _ctx.mumberInfo.card), 1),
                _createElementVNode("div", null, [
                  (_ctx.mumberInfo.role !== 'member')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_32, _toDisplayString(_ctx.$t('chat_member_type_' + _ctx.mumberInfo.role)), 1))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", null, "Lv " + _toDisplayString(_ctx.mumberInfo.level), 1)
                ])
              ]),
              (_ctx.mumberInfo.join_time)
                ? (_openBlock(), _createElementBlock("span", _hoisted_33, _toDisplayString(_ctx.$t('chat_join_time', {
                            time: Intl.DateTimeFormat(_ctx.trueLang,
                                { year: 'numeric', month: "short", day: "numeric" }).format(new Date(_ctx.mumberInfo.join_time * 1000))
                        })), 1))
                : _createCommentVNode("", true)
            ])
          ], 4))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass('msg-menu' + (_ctx.runtimeData.sysConfig.opt_no_window ? ' withBar': ''))
    }, [
      _withDirectives(_createElementVNode("div", {
        class: "msg-menu-bg",
        onClick: _cache[26] || (_cache[26] = 
//@ts-ignore
(...args) => (_ctx.closeMsgMenu && _ctx.closeMsgMenu(...args)))
      }, null, 512), [
        [_vShow, _ctx.tags.showMsgMenu]
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.tags.showMsgMenu ? 'ss-card msg-menu-body show' : 'ss-card msg-menu-body'),
        id: "msgMenu"
      }, [
        (_ctx.runtimeData.chatInfo.show.type == 'group')
          ? _withDirectives((_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass('ss-card respond' + (_ctx.tags.menuDisplay.respond ? ' open': ''))
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.respondIds, (num, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, {
                  key: 'respond-' + num
                }, [
                  (_ctx.getFace(num) != false)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        onClick: ($event: any) => (_ctx.sendRespond(num)),
                        loading: "lazy",
                        src: (_ctx.getFace(num) as any)
                      }, null, 8, _hoisted_34))
                    : _createCommentVNode("", true),
                  (index == 4)
                    ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                        key: 1,
                        onClick: _cache[27] || (_cache[27] = ($event: any) => (_ctx.tags.menuDisplay.respond = true)),
                        icon: ['fas', 'angle-up']
                      }))
                    : _createCommentVNode("", true)
                ], 64))
              }), 128))
            ], 2)), [
              [_vShow, _ctx.tags.menuDisplay.showRespond]
            ])
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("div", {
          onClick: _cache[28] || (_cache[28] = ($event: any) => (_ctx.forwardSelf()))
        }, [
          _createElementVNode("div", null, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'plus'] })
          ]),
          _createElementVNode("a", null, _toDisplayString(_ctx.$t('chat_msg_menu_plus')), 1)
        ], 512), [
          [_vShow, _ctx.tags.menuDisplay.add]
        ]),
        _withDirectives(_createElementVNode("div", {
          onClick: _cache[29] || (_cache[29] = ($event: any) => (_ctx.replyMsg(true)))
        }, [
          _createElementVNode("div", null, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'message'] })
          ]),
          _createElementVNode("a", null, _toDisplayString(_ctx.$t('chat_msg_menu_reply')), 1)
        ], 512), [
          [_vShow, _ctx.tags.menuDisplay.relpy]
        ]),
        _withDirectives(_createElementVNode("div", {
          onClick: _cache[30] || (_cache[30] = ($event: any) => (_ctx.showForWard()))
        }, [
          _createElementVNode("div", null, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'share'] })
          ]),
          _createElementVNode("a", null, _toDisplayString(_ctx.$t('chat_msg_menu_forward')), 1)
        ], 512), [
          [_vShow, _ctx.tags.menuDisplay.forward]
        ]),
        _withDirectives(_createElementVNode("div", {
          onClick: _cache[31] || (_cache[31] = 
//@ts-ignore
(...args) => (_ctx.copyMsg && _ctx.copyMsg(...args)))
        }, [
          _createElementVNode("div", null, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'clipboard'] })
          ]),
          _createElementVNode("a", null, _toDisplayString(_ctx.$t('chat_msg_menu_copy')), 1)
        ], 512), [
          [_vShow, _ctx.tags.menuDisplay.copy]
        ]),
        _withDirectives(_createElementVNode("div", {
          onClick: _cache[32] || (_cache[32] = 
//@ts-ignore
(...args) => (_ctx.copySelectMsg && _ctx.copySelectMsg(...args)))
        }, [
          _createElementVNode("div", null, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'code'] })
          ]),
          _createElementVNode("a", null, _toDisplayString(_ctx.$t('chat_msg_menu_copy_selected')), 1)
        ], 512), [
          [_vShow, _ctx.tags.menuDisplay.copySelect]
        ]),
        _withDirectives(_createElementVNode("div", {
          onClick: _cache[33] || (_cache[33] = 
//@ts-ignore
(...args) => (_ctx.downloadImg && _ctx.downloadImg(...args)))
        }, [
          _createElementVNode("div", null, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'floppy-disk'] })
          ]),
          _createElementVNode("a", null, _toDisplayString(_ctx.$t('chat_msg_menu_download_img')), 1)
        ], 512), [
          [_vShow, _ctx.tags.menuDisplay.downloadImg != false]
        ]),
        _withDirectives(_createElementVNode("div", {
          onClick: _cache[34] || (_cache[34] = 
//@ts-ignore
(...args) => (_ctx.addStoreFace && _ctx.addStoreFace(...args)))
        }, [
          _createElementVNode("div", null, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'heart'] })
          ]),
          _createElementVNode("a", null, _toDisplayString(_ctx.$t('chat_msg_menu_add_store_face')), 1)
        ], 512), [
          [_vShow, _ctx.tags.menuDisplay.addStoreFace != false]
        ]),
        _withDirectives(_createElementVNode("div", {
          onClick: _cache[35] || (_cache[35] = 
//@ts-ignore
(...args) => (_ctx.revokeMsg && _ctx.revokeMsg(...args)))
        }, [
          _createElementVNode("div", null, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'xmark'] })
          ]),
          _createElementVNode("a", null, _toDisplayString(_ctx.$t('chat_msg_menu_withdraw')), 1)
        ], 512), [
          [_vShow, _ctx.tags.menuDisplay.revoke]
        ]),
        _withDirectives(_createElementVNode("div", {
          onClick: _cache[36] || (_cache[36] = ($event: any) => {(_ctx.selectedMsg ? _ctx.addSpecialMsg({ msgObj: { type: 'at', qq: _ctx.selectedMsg.sender.user_id }, addText: true }) : '');_ctx.toMainInput();_ctx.closeMsgMenu();})
        }, [
          _createElementVNode("div", null, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'at'] })
          ]),
          _createElementVNode("a", null, _toDisplayString(_ctx.$t('chat_msg_menu_at')), 1)
        ], 512), [
          [_vShow, _ctx.tags.menuDisplay.at]
        ]),
        _withDirectives(_createElementVNode("div", {
          onClick: _cache[37] || (_cache[37] = 
//@ts-ignore
(...args) => (_ctx.removeUser && _ctx.removeUser(...args)))
        }, [
          _createElementVNode("div", null, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'trash-can'] })
          ]),
          _createElementVNode("a", null, _toDisplayString(_ctx.$t('chat_msg_menu_remove')), 1)
        ], 512), [
          [_vShow, _ctx.tags.menuDisplay.remove]
        ])
      ], 2)
    ], 2),
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        _createVNode(_component_Info, {
          chat: _ctx.chat,
          tags: _ctx.tags,
          onClose: _ctx.openChatInfoPan,
          onLoadFile: _ctx.fileLoad
        }, null, 8, ["chat", "tags", "onClose", "onLoadFile"])
      ]),
      _: 1
    }),
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_35, [
          _createElementVNode("div", _hoisted_36, [
            _createElementVNode("div", _hoisted_37, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('chat_send_pic_title')), 1),
              _createElementVNode("button", {
                onClick: _cache[38] || (_cache[38] = 
//@ts-ignore
(...args) => (_ctx.sendMsg && _ctx.sendMsg(...args))),
                class: "ss-button"
              }, _toDisplayString(_ctx.$t('chat_send')), 1)
            ]),
            _createElementVNode("div", _hoisted_38, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.imgCache, (img64, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: 'sendImg-' + index
                }, [
                  _createElementVNode("div", {
                    onClick: ($event: any) => (_ctx.deleteImg(index))
                  }, [
                    _createVNode(_component_font_awesome_icon, { icon: ['fas', 'xmark'] })
                  ], 8, _hoisted_39),
                  _createElementVNode("img", { src: img64 }, null, 8, _hoisted_40)
                ]))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_41, [
              _createVNode(_component_font_awesome_icon, {
                onClick: _ctx.runSelectImg,
                icon: ['fas', 'image']
              }, null, 8, ["onClick"]),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                onPaste: _cache[39] || (_cache[39] = 
//@ts-ignore
(...args) => (_ctx.addImg && _ctx.addImg(...args))),
                disabled: _ctx.runtimeData.tags.openSideBar,
                onClick: _cache[40] || (_cache[40] = 
//@ts-ignore
(...args) => (_ctx.toMainInput && _ctx.toMainInput(...args))),
                "onUpdate:modelValue": _cache[41] || (_cache[41] = ($event: any) => ((_ctx.msg) = $event))
              }, null, 40, _hoisted_42), [
                [_vModelText, _ctx.msg]
              ])
            ])
          ]),
          _createElementVNode("div", {
            class: "bg",
            onClick: _cache[42] || (_cache[42] = ($event: any) => (_ctx.imgCache = []))
          })
        ], 512), [
          [_vShow, _ctx.imgCache.length > 0]
        ])
      ]),
      _: 1
    }),
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        (_ctx.tags.showForwardPan)
          ? (_openBlock(), _createElementBlock("div", _hoisted_43, [
              _createElementVNode("div", _hoisted_44, [
                _createElementVNode("header", null, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('chat_msg_forward_pan')), 1),
                  _createVNode(_component_font_awesome_icon, {
                    onClick: _ctx.cancelForward,
                    icon: ['fas', 'xmark']
                  }, null, 8, ["onClick"])
                ]),
                _createElementVNode("input", {
                  onInput: _cache[43] || (_cache[43] = 
//@ts-ignore
(...args) => (_ctx.searchForward && _ctx.searchForward(...args))),
                  placeholder: _ctx.$t('base_search')
                }, null, 40, _hoisted_45),
                _createElementVNode("div", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.forwardList, (data) => {
                    return (_openBlock(), _createElementBlock("div", {
                      onClick: ($event: any) => (_ctx.forwardMsg(data)),
                      key: 'forwardList-' + data.user_id ? data.user_id : data.group_id
                    }, [
                      _createElementVNode("img", {
                        loading: "lazy",
                        title: data.group_name ? data.group_name :
                                data.remark === data.nickname ? data.nickname : data.remark + '（' + data.nickname + '）',
                        src: data.user_id ?
                                'https://q1.qlogo.cn/g?b=qq&s=0&nk=' + data.user_id :
                                'https://p.qlogo.cn/gh/' + data.group_id + '/' + data.group_id + '/0'
                      }, null, 8, _hoisted_47),
                      _createElementVNode("div", null, [
                        _createElementVNode("p", null, _toDisplayString(data.group_name ? data.group_name :
                                        data.remark === data.nickname ? data.nickname : data.remark + '（' + data.nickname + '）'), 1),
                        _createElementVNode("span", null, _toDisplayString(data.group_id ? _ctx.$t('chat_type_group')  : _ctx.$t('chat_type_user')), 1)
                      ])
                    ], 8, _hoisted_46))
                  }), 128))
                ])
              ]),
              _createElementVNode("div", {
                class: "bg",
                onClick: _cache[44] || (_cache[44] = 
//@ts-ignore
(...args) => (_ctx.cancelForward && _ctx.cancelForward(...args)))
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("div", {
      class: "bg",
      style: _normalizeStyle(_ctx.runtimeData.sysConfig.option_view_background ? `backdrop-filter: blur(${_ctx.runtimeData.sysConfig.chat_background_blur}px);` : '')
    }, null, 4)
  ], 6))
}