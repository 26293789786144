import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "friend-view" }
const _hoisted_2 = { class: "base" }
const _hoisted_3 = { class: "small" }
const _hoisted_4 = ["placeholder"]
const _hoisted_5 = ["placeholder"]
const _hoisted_6 = ["title", "onClick"]
const _hoisted_7 = ["id"]
const _hoisted_8 = ["title"]
const _hoisted_9 = {
  key: 1,
  class: "list"
}
const _hoisted_10 = { class: "ss-card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_FriendBody = _resolveComponent("FriendBody")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass('friend-list' + (_ctx.runtimeData.tags.openSideBar ? ' open' : '')),
      id: "friend-list"
    }, [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('friend_title')), 1),
          _cache[7] || (_cache[7] = _createElementVNode("div", { style: {"flex":"1"} }, null, -1)),
          _createVNode(_component_font_awesome_icon, {
            onClick: _ctx.reloadUser,
            icon: ['fas', 'rotate-right']
          }, null, 8, ["onClick"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("label", null, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchInfo) = $event)),
              onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.search && _ctx.search(...args))),
              type: "text",
              placeholder: _ctx.$t('base_search')
            }, null, 40, _hoisted_4), [
              [_vModelText, _ctx.searchInfo]
            ]),
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'magnifying-glass'] })
          ]),
          _createElementVNode("div", {
            class: "reload",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.reloadUser && _ctx.reloadUser(...args)))
          }, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'rotate-right'] })
          ]),
          _createElementVNode("div", {
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.openLeftBar && _ctx.openLeftBar(...args)))
          }, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'bars-staggered'] })
          ])
        ]),
        _createElementVNode("label", null, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.searchInfo) = $event)),
            onInput: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.search && _ctx.search(...args))),
            type: "text",
            placeholder: _ctx.$t('base_search')
          }, null, 40, _hoisted_5), [
            [_vModelText, _ctx.searchInfo]
          ]),
          _createVNode(_component_font_awesome_icon, { icon: ['fas', 'magnifying-glass'] })
        ])
      ]),
      _createElementVNode("div", {
        class: _normalizeClass((_ctx.runtimeData.tags.openSideBar ? 'open' : ''))
      }, [
        (_ctx.runtimeData.showList.length <= 0)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.runtimeData.tags.classes, (info) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: 'class-' + info.class_id,
                  class: _normalizeClass('list exp-body' + (_ctx.classStatus[info.class_id] == true ? ' open' : ''))
                }, [
                  _createElementVNode("header", {
                    title: info.class_name,
                    class: _normalizeClass('exp-header' + (_ctx.runtimeData.tags.openSideBar ? ' open' : '')),
                    onClick: ($event: any) => (_ctx.classClick(info.class_id))
                  }, [
                    _cache[8] || (_cache[8] = _createElementVNode("div", null, null, -1)),
                    _createElementVNode("span", null, _toDisplayString(info.class_name), 1),
                    _createElementVNode("a", null, _toDisplayString(info.user_count ?? _ctx.runtimeData.userList.filter((get) => { return get.class_id == info.class_id }).length), 1)
                  ], 10, _hoisted_6),
                  _createElementVNode("div", {
                    id: 'class-' + info.class_id
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.runtimeData.userList.filter((get) => { return get.class_id == info.class_id }), (item) => {
                      return (_openBlock(), _createBlock(_component_FriendBody, {
                        key: 'fb-' + (item.user_id ? item.user_id : item.group_id),
                        data: item,
                        from: "friend",
                        onClick: ($event: any) => (_ctx.userClick(item, $event))
                      }, null, 8, ["data", "onClick"]))
                    }), 128))
                  ], 8, _hoisted_7)
                ], 2))
              }), 128)),
              _createElementVNode("div", {
                class: _normalizeClass('list exp-body' + (_ctx.classStatus['-1'] == true ? ' open' : ''))
              }, [
                _createElementVNode("header", {
                  title: _ctx.$t('friend_group'),
                  class: _normalizeClass('exp-header' + (_ctx.runtimeData.tags.openSideBar ? ' open' : '')),
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.classClick('-1')))
                }, [
                  _cache[9] || (_cache[9] = _createElementVNode("div", null, null, -1)),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('friend_group')), 1),
                  _createElementVNode("a", null, _toDisplayString(_ctx.runtimeData.userList.filter((get) => { return get.class_id == undefined }).length), 1)
                ], 10, _hoisted_8),
                _createElementVNode("div", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.runtimeData.userList.filter((get) => { return get.class_id == undefined }), (item) => {
                    return (_openBlock(), _createBlock(_component_FriendBody, {
                      key: 'fb-' + (item.user_id ? item.user_id : item.group_id),
                      data: item,
                      from: "friend",
                      onClick: ($event: any) => (_ctx.userClick(item, $event))
                    }, null, 8, ["data", "onClick"]))
                  }), 128))
                ])
              ], 2)
            ], 64))
          : (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("div", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.runtimeData.showList, (item) => {
                  return (_openBlock(), _createBlock(_component_FriendBody, {
                    key: 'fb-' + (item.user_id ? item.user_id : item.group_id),
                    data: item,
                    from: "friend",
                    onClick: ($event: any) => (_ctx.userClick(item, $event))
                  }, null, 8, ["data", "onClick"]))
                }), 128))
              ])
            ]))
      ], 2)
    ], 2),
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass('friend-list-space' + (_ctx.runtimeData.tags.openSideBar ? ' open' : ''))
    }, [
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_font_awesome_icon, { icon: ['fas', 'inbox'] }),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('chat_space')), 1)
      ])
    ], 2), [
      [_vShow, !_ctx.loginInfo.status || _ctx.runtimeData.chatInfo.show.id == 0]
    ])
  ]))
}