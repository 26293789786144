import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["id", "data-name", "data-nickname", "data-type"]
const _hoisted_2 = ["title", "src"]
const _hoisted_3 = {
  key: 0,
  class: "time"
}
const _hoisted_4 = {
  key: 0,
  style: {"margin-left":"10px","display":"flex"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass('friend-body' + (_ctx.select ? ' active' : (_ctx.menu ? ' onmenu' : ''))),
    id: 'user-' + (_ctx.data.user_id ?? _ctx.data.group_id),
    "data-name": _ctx.data.user_id ? _ctx.data.nickname : _ctx.data.group_name,
    "data-nickname": _ctx.data.user_id ? _ctx.data.nickname : '',
    "data-type": _ctx.data.user_id ? 'friend' : 'group'
  }, [
    _createElementVNode("div", {
      class: _normalizeClass((_ctx.data.new_msg === true ? 'new' : ''))
    }, null, 2),
    (_ctx.data.user_id == -10000)
      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 0,
          icon: ['fas', 'bell']
        }))
      : (_openBlock(), _createElementBlock("img", {
          key: 1,
          loading: "lazy",
          title: _ctx.getShowName(),
          src: _ctx.data.user_id ?
                    'https://q1.qlogo.cn/g?b=qq&s=0&nk=' + _ctx.data.user_id :
                    'https://p.qlogo.cn/gh/' + _ctx.data.group_id + '/' + _ctx.data.group_id + '/0'
        }, null, 8, _hoisted_2)),
    _createElementVNode("div", null, [
      _createElementVNode("div", null, [
        _createElementVNode("p", null, _toDisplayString(_ctx.getShowName()), 1),
        _cache[0] || (_cache[0] = _createElementVNode("div", { style: {"flex":"1"} }, null, -1)),
        (_ctx.from == 'message')
          ? (_openBlock(), _createElementBlock("a", _hoisted_3, _toDisplayString(_ctx.data.time !== undefined ? Intl.DateTimeFormat(_ctx.trueLang,
                        { hour: "numeric", minute: "numeric" }).format(new Date(_ctx.data.time)) : ''), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("a", {
          class: _normalizeClass(_ctx.from == 'friend' ? 'nick' : '')
        }, _toDisplayString(_ctx.from == 'friend' ? _ctx.data.longNick ?? '' : _ctx.data.raw_msg), 3),
        (_ctx.from == 'message')
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_ctx.data.always_top === true)
                ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                    key: 0,
                    icon: ['fas', 'thumbtack']
                  }))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 10, _hoisted_1))
}