import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "chat-info-pan"
}
const _hoisted_2 = { class: "ss-card chat-info" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["src"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "tags" }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "outher" }
const _hoisted_12 = ["name"]
const _hoisted_13 = { class: "chat-info-tab-member" }
const _hoisted_14 = { class: "search-view" }
const _hoisted_15 = ["placeholder"]
const _hoisted_16 = ["onClick"]
const _hoisted_17 = ["src"]
const _hoisted_18 = ["name"]
const _hoisted_19 = { class: "bulletins" }
const _hoisted_20 = ["name"]
const _hoisted_21 = { class: "group-files-loader" }
const _hoisted_22 = ["name"]
const _hoisted_23 = { style: {"padding":"0 20px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_OptInfo = _resolveComponent("OptInfo")!
  const _component_BulletinBody = _resolveComponent("BulletinBody")!
  const _component_FileBody = _resolveComponent("FileBody")!
  const _component_BcTab = _resolveComponent("BcTab")!

  return (_ctx.tags.openChatInfo)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("header", null, [
            (_ctx.chat.show.type === 'group')
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t('chat_chat_info_group')), 1))
              : _createCommentVNode("", true),
            (_ctx.chat.show.type === 'user')
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t('chat_chat_info_user')), 1))
              : _createCommentVNode("", true),
            _createVNode(_component_font_awesome_icon, {
              onClick: _ctx.closeChatInfoPan,
              icon: ['fas', 'xmark']
            }, null, 8, ["onClick"])
          ]),
          _createElementVNode("div", {
            class: _normalizeClass('chat-info-base ' + _ctx.chat.show.type)
          }, [
            _createElementVNode("div", null, [
              _createElementVNode("img", {
                src: _ctx.chat.show.avatar
              }, null, 8, _hoisted_5),
              _createElementVNode("div", null, [
                _createElementVNode("a", null, _toDisplayString(_ctx.chat.show.name), 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.chat.show.id), 1)
              ])
            ]),
            (_ctx.chat.show.type === 'group')
              ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("header", null, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('chat_chat_info_introduction')), 1)
                  ]),
                  _createElementVNode("span", {
                    innerHTML: (_ctx.chat.info.group_info.gIntro === undefined || _ctx.chat.info.group_info.gIntro === '') ?
                    _ctx.$t('chat_chat_info_nointroduction') : _ctx.chat.info.group_info.gIntro
                  }, null, 8, _hoisted_7),
                  _createElementVNode("div", _hoisted_8, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chat.info.group_info.tags, (item) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: item.md
                      }, _toDisplayString(item.tag), 1))
                    }), 128))
                  ])
                ], 512)), [
                  [_vShow, Object.keys(_ctx.chat.info.group_info).length > 0]
                ])
              : (_ctx.chat.show.type === 'user')
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createElementVNode("header", null, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('chat_chat_info_lnick')), 1)
                    ]),
                    _createElementVNode("span", {
                      innerHTML: (_ctx.chat.info.user_info.lnick === undefined || _ctx.chat.info.user_info.lnick === '') ?
                    _ctx.$t('chat_chat_info_nolnick') : _ctx.chat.info.user_info.lnick
                    }, null, 8, _hoisted_10),
                    _createElementVNode("header", null, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('chat_chat_info_other')), 1)
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("span", null, [
                        _createTextVNode(_toDisplayString(_ctx.$t('chat_chat_info_birthday')) + ": ", 1),
                        _createElementVNode("span", null, _toDisplayString(_ctx.chat.info.user === undefined ? '' : Intl.DateTimeFormat(_ctx.trueLang,
                                        { year: 'numeric', month: "short", day: "numeric" }).format(
                                            new
                                                Date(`${_ctx.chat.info.user_info.birthday.year}-${_ctx.chat.info.user_info.birthday.month}-${_ctx.chat.info.user_info.birthday.day}`)
                                        ) + ` (${_ctx.$t('chat_chat_info_chinese_zodiac').split('&')[_ctx.chat.info.user_info.shengxiao -
                                        1]})`), 1)
                      ]),
                      _createElementVNode("span", null, [
                        _createTextVNode(_toDisplayString(_ctx.$t('chat_chat_info_district')) + ": ", 1),
                        _createElementVNode("span", null, _toDisplayString(`${_ctx.chat.info.user_info.country}-${_ctx.chat.info.user_info.province}-${_ctx.chat.info.user_info.city}`), 1)
                      ])
                    ]),
                    (!_ctx.chat.show.temp)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createElementVNode("header", null, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t('chat_chat_info_config')), 1)
                          ]),
                          _createVNode(_component_OptInfo, {
                            type: 'number',
                            chat: _ctx.chat
                          }, null, 8, ["chat"])
                        ], 64))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
          ], 2),
          (_ctx.chat.show.type === 'group')
            ? (_openBlock(), _createBlock(_component_BcTab, {
                key: 0,
                class: "chat-info-tab"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    name: _ctx.$t('chat_chat_info_member')
                  }, [
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("input", {
                          placeholder: _ctx.$t('base_search'),
                          onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.searchList && _ctx.searchList(...args)))
                        }, null, 40, _hoisted_15)
                      ]),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.number_cache.length > 0 ? _ctx.number_cache : _ctx.chat.info.group_members, (item) => {
                        return (_openBlock(), _createElementBlock("div", {
                          onClick: ($event: any) => (_ctx.startChat(item)),
                          key: 'chatinfomlist-' + item.user_id
                        }, [
                          _createElementVNode("img", {
                            loading: "lazy",
                            src: `https://q1.qlogo.cn/g?b=qq&s=0&nk=${item.user_id}`
                          }, null, 8, _hoisted_17),
                          _createElementVNode("div", null, [
                            _createElementVNode("a", null, _toDisplayString(item.card ? item.card : item.nickname), 1),
                            (item.role === 'owner')
                              ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                                  key: 0,
                                  icon: ['fas', 'crown']
                                }))
                              : _createCommentVNode("", true),
                            (item.role === 'admin')
                              ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                                  key: 1,
                                  icon: ['fas', 'star']
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          _createElementVNode("span", null, _toDisplayString(item.user_id), 1)
                        ], 8, _hoisted_16))
                      }), 128))
                    ])
                  ], 8, _hoisted_12),
                  _createElementVNode("div", {
                    name: _ctx.$t('chat_chat_info_notice')
                  }, [
                    _createElementVNode("div", _hoisted_19, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chat.info.group_notices ?? [], (item, index) => {
                        return (_openBlock(), _createBlock(_component_BulletinBody, {
                          data: item,
                          key: 'bulletins-' + index,
                          index: index
                        }, null, 8, ["data", "index"]))
                      }), 128))
                    ])
                  ], 8, _hoisted_18),
                  _createElementVNode("div", {
                    name: _ctx.$t('chat_chat_info_file')
                  }, [
                    _createElementVNode("div", {
                      class: "group-files",
                      onScroll: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.fileLoad && _ctx.fileLoad(...args)))
                    }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chat.info.group_files.file_list, (item) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: 'file-' + item.id
                        }, [
                          _createVNode(_component_FileBody, {
                            chat: _ctx.chat,
                            item: item
                          }, null, 8, ["chat", "item"])
                        ]))
                      }), 128)),
                      _withDirectives(_createElementVNode("div", _hoisted_21, [
                        _createVNode(_component_font_awesome_icon, { icon: ['fas', 'ellipsis'] })
                      ], 512), [
                        [_vShow, _ctx.chat.info.group_files !== undefined &&
                        _ctx.chat.info.group_files.next_index !== undefined &&
                        _ctx.chat.info.group_files.next_index !== 0]
                      ])
                    ], 32)
                  ], 8, _hoisted_20),
                  _createElementVNode("div", {
                    name: _ctx.$t('chat_chat_info_config')
                  }, [
                    _createElementVNode("div", _hoisted_23, [
                      _createVNode(_component_OptInfo, {
                        type: 'group',
                        chat: _ctx.chat
                      }, null, 8, ["chat"])
                    ])
                  ], 8, _hoisted_22)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "card-info-pan-bg" }, null, -1))
      ]))
    : _createCommentVNode("", true)
}