import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, vModelText as _vModelText } from "vue"

const _hoisted_1 = { class: "opt-page" }
const _hoisted_2 = { class: "ss-card" }
const _hoisted_3 = { class: "l10n-info" }
const _hoisted_4 = { class: "author" }
const _hoisted_5 = { class: "opt-item" }
const _hoisted_6 = ["value"]
const _hoisted_7 = { class: "ss-card" }
const _hoisted_8 = {
  class: "opt-item",
  id: "opt_view_dark"
}
const _hoisted_9 = { class: "ss-switch" }
const _hoisted_10 = { class: "opt-item" }
const _hoisted_11 = { class: "ss-switch" }
const _hoisted_12 = {
  key: 0,
  class: "opt-item"
}
const _hoisted_13 = { class: "theme-color-col" }
const _hoisted_14 = ["title"]
const _hoisted_15 = ["data-id", "checked"]
const _hoisted_16 = {
  key: 1,
  class: "opt-item"
}
const _hoisted_17 = { class: "ss-switch" }
const _hoisted_18 = {
  key: 2,
  class: "opt-item"
}
const _hoisted_19 = { class: "ss-switch" }
const _hoisted_20 = { class: "opt-item" }
const _hoisted_21 = { value: "" }
const _hoisted_22 = ["value"]
const _hoisted_23 = { class: "opt-item" }
const _hoisted_24 = { class: "opt-item" }
const _hoisted_25 = { class: "ss-range" }
const _hoisted_26 = {
  key: 3,
  class: "opt-item"
}
const _hoisted_27 = { value: "default" }
const _hoisted_28 = { value: "vibrancy" }
const _hoisted_29 = { value: "transparent" }
const _hoisted_30 = { class: "ss-card" }
const _hoisted_31 = {
  key: 0,
  class: "opt-item"
}
const _hoisted_32 = { class: "ss-range" }
const _hoisted_33 = {
  key: 1,
  class: "opt-item"
}
const _hoisted_34 = { class: "ss-range" }
const _hoisted_35 = {
  key: 2,
  class: "opt-item"
}
const _hoisted_36 = { class: "ss-switch" }
const _hoisted_37 = { class: "opt-item" }
const _hoisted_38 = { class: "ss-switch" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("header", null, _toDisplayString(_ctx.$t('option_view_l10n')), 1),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_font_awesome_icon, { icon: ['fas', 'language'] }),
        _createElementVNode("div", null, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('l10n_name')), 1),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('l10n_author_title')) + _toDisplayString(_ctx.$t('l10n_author')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('l10n_description')), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_font_awesome_icon, { icon: ['fas', 'earth-asia'] }),
        _createElementVNode("div", null, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_view_language')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_view_language_tip')), 1)
        ]),
        _withDirectives(_createElementVNode("select", {
          onChange: _cache[0] || (_cache[0] = ($event: any) => {_ctx.save($event); _ctx.gaLanguage($event)}),
          name: "language",
          title: "language",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.runtimeData.sysConfig.language) = $event))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languages, (item) => {
            return (_openBlock(), _createElementBlock("option", {
              value: item.value,
              key: item.value
            }, _toDisplayString(item.name), 9, _hoisted_6))
          }), 128))
        ], 544), [
          [_vModelSelect, _ctx.runtimeData.sysConfig.language]
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("header", null, _toDisplayString(_ctx.$t('option_view_theme')), 1),
      (_ctx.runtimeData.sysConfig.opt_auto_gtk != true)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_font_awesome_icon, { icon: ['fas', 'moon'] }),
              _createElementVNode("div", null, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_view_dark_mode')), 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_view_dark_mode_tip')), 1)
              ]),
              _createElementVNode("label", _hoisted_9, [
                _withDirectives(_createElementVNode("input", {
                  type: "checkbox",
                  onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args))),
                  name: "opt_dark",
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.runtimeData.sysConfig.opt_dark) = $event))
                }, null, 544), [
                  [_vModelCheckbox, _ctx.runtimeData.sysConfig.opt_dark]
                ]),
                _cache[29] || (_cache[29] = _createElementVNode("div", null, [
                  _createElementVNode("div")
                ], -1))
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_font_awesome_icon, { icon: ['fas', 'toggle-on'] }),
              _createElementVNode("div", null, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_view_auto_dark')), 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_view_auto_dark_tip')), 1)
              ]),
              _createElementVNode("label", _hoisted_11, [
                _withDirectives(_createElementVNode("input", {
                  type: "checkbox",
                  onChange: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args))),
                  name: "opt_auto_dark",
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.runtimeData.sysConfig.opt_auto_dark) = $event))
                }, null, 544), [
                  [_vModelCheckbox, _ctx.runtimeData.sysConfig.opt_auto_dark]
                ]),
                _cache[30] || (_cache[30] = _createElementVNode("div", null, [
                  _createElementVNode("div")
                ], -1))
              ])
            ]),
            (_ctx.runtimeData.sysConfig.opt_auto_win_color != true)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createVNode(_component_font_awesome_icon, { icon: ['fas', 'palette'] }),
                  _createElementVNode("div", null, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_view_theme_color')), 1),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_view_theme_color_tip')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.colors, (name, index) => {
                      return (_openBlock(), _createElementBlock("label", {
                        title: name,
                        key: 'color_id_' + index,
                        class: "ss-radio"
                      }, [
                        _createElementVNode("input", {
                          type: "radio",
                          name: "theme_color",
                          onChange: _cache[6] || (_cache[6] = ($event: any) => {_ctx.save($event); _ctx.gaColor($event)}),
                          "data-id": index,
                          checked: _ctx.runtimeData.sysConfig.theme_color === undefined ? index === 0 : Number(_ctx.runtimeData.sysConfig.theme_color) === index
                        }, null, 40, _hoisted_15),
                        _createElementVNode("div", {
                          style: _normalizeStyle('background: var(--color-main-' + index + ');')
                        }, _cache[31] || (_cache[31] = [
                          _createElementVNode("div", null, null, -1)
                        ]), 4)
                      ], 8, _hoisted_14))
                    }), 128))
                  ])
                ]))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true),
      (_ctx.runtimeData.tags.isElectron && _ctx.browser.os == 'Linux')
        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'window-restore'] }),
            _createElementVNode("div", null, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_view_auto_gtk')), 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_view_auto_gtk_tip')), 1)
            ]),
            _createElementVNode("label", _hoisted_17, [
              _withDirectives(_createElementVNode("input", {
                type: "checkbox",
                onChange: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args))),
                name: "opt_auto_gtk",
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.runtimeData.sysConfig.opt_auto_gtk) = $event))
              }, null, 544), [
                [_vModelCheckbox, _ctx.runtimeData.sysConfig.opt_auto_gtk]
              ]),
              _cache[32] || (_cache[32] = _createElementVNode("div", null, [
                _createElementVNode("div")
              ], -1))
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.runtimeData.tags.isElectron && _ctx.browser.os != 'Linux')
        ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'wand-magic-sparkles'] }),
            _createElementVNode("div", null, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_view_auto_win_color')), 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_view_auto_win_color_tip')), 1)
            ]),
            _createElementVNode("label", _hoisted_19, [
              _withDirectives(_createElementVNode("input", {
                type: "checkbox",
                onChange: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args))),
                name: "opt_auto_win_color",
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.runtimeData.sysConfig.opt_auto_win_color) = $event))
              }, null, 544), [
                [_vModelCheckbox, _ctx.runtimeData.sysConfig.opt_auto_win_color]
              ]),
              _cache[33] || (_cache[33] = _createElementVNode("div", null, [
                _createElementVNode("div")
              ], -1))
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_20, [
        _createVNode(_component_font_awesome_icon, { icon: ['fas', 'table-columns'] }),
        _createElementVNode("div", null, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_dev_chatview_name')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_dev_chatview_name_tip')), 1)
        ]),
        _withDirectives(_createElementVNode("select", {
          onChange: _cache[11] || (_cache[11] = ($event: any) => {_ctx.save($event); _ctx.gaChatView($event)}),
          name: "chatview_name",
          title: "chatview_name",
          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.chatview_name) = $event))
        }, [
          _createElementVNode("option", _hoisted_21, _toDisplayString(_ctx.$t('option_dev_chatview_name_none')), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getAppendChatView(), (item) => {
            return (_openBlock(), _createElementBlock("option", {
              value: item,
              key: item
            }, _toDisplayString(item.replace('Chat', '')), 9, _hoisted_22))
          }), 128))
        ], 544), [
          [_vModelSelect, _ctx.chatview_name]
        ])
      ]),
      _createElementVNode("div", _hoisted_23, [
        _createVNode(_component_font_awesome_icon, { icon: ['fas', 'image'] }),
        _createElementVNode("div", null, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_view_background')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_view_background_tip')), 1)
        ]),
        _withDirectives(_createElementVNode("input", {
          class: "ss-input",
          style: {"width":"150px"},
          type: "text",
          name: "chat_background",
          onKeyup: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args))),
          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.runtimeData.sysConfig.chat_background) = $event))
        }, null, 544), [
          [_vModelText, _ctx.runtimeData.sysConfig.chat_background]
        ])
      ]),
      _createElementVNode("div", _hoisted_24, [
        _createVNode(_component_font_awesome_icon, { icon: ['fas', 'o'] }),
        _createElementVNode("div", null, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_view_background_blur')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_view_background_blur_tip')), 1)
        ]),
        _createElementVNode("div", _hoisted_25, [
          _withDirectives(_createElementVNode("input", {
            style: _normalizeStyle(`background-size: ${_ctx.runtimeData.sysConfig.chat_background_blur}% 100%;`),
            type: "range",
            "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.runtimeData.sysConfig.chat_background_blur) = $event)),
            name: "chat_background_blur",
            onInput: _cache[16] || (_cache[16] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)))
          }, null, 36), [
            [_vModelText, _ctx.runtimeData.sysConfig.chat_background_blur]
          ]),
          _createElementVNode("span", {
            style: _normalizeStyle(`color: var(--color-font${_ctx.runtimeData.sysConfig.chat_background_blur > 50 ? '-r' : ''})`)
          }, _toDisplayString(_ctx.runtimeData.sysConfig.chat_background_blur) + " px", 5)
        ])
      ]),
      (_ctx.runtimeData.tags.isElectron && _ctx.browser.os != 'Linux')
        ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'border-none'] }),
            _createElementVNode("div", null, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_view_vibrancy_mode')), 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_view_vibrancy_mode_tip')), 1)
            ]),
            _withDirectives(_createElementVNode("select", {
              "data-reload": "true",
              onChange: _cache[17] || (_cache[17] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args))),
              name: "vibrancy_mode",
              title: "vibrancy_mode",
              "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.runtimeData.sysConfig.vibrancy_mode) = $event))
            }, [
              _createElementVNode("option", _hoisted_27, _toDisplayString(_ctx.$t('option_default')), 1),
              _createElementVNode("option", _hoisted_28, _toDisplayString(_ctx.$t('option_vibrancy_mode_vibrancy')), 1),
              _createElementVNode("option", _hoisted_29, _toDisplayString(_ctx.$t('option_vibrancy_mode_transparent')), 1)
            ], 544), [
              [_vModelSelect, _ctx.runtimeData.sysConfig.vibrancy_mode]
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_30, [
      _createElementVNode("header", null, _toDisplayString(_ctx.$t('option_view_view')), 1),
      (_ctx.isMobile())
        ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'up-down-left-right'] }),
            _createElementVNode("div", null, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_view_initial_scale')), 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_view_initial_scale_tip')), 1)
            ]),
            _createElementVNode("div", _hoisted_32, [
              _withDirectives(_createElementVNode("input", {
                style: _normalizeStyle(`background-size: ${_ctx.initialScaleShow / 0.05}% 100%;`),
                type: "range",
                min: "0.1",
                max: "5",
                step: "0.05",
                "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.runtimeData.sysConfig.initial_scale) = $event)),
                name: "initial_scale",
                onChange: _cache[20] || (_cache[20] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args))),
                onInput: _cache[21] || (_cache[21] = 
//@ts-ignore
(...args) => (_ctx.setInitialScaleShow && _ctx.setInitialScaleShow(...args)))
              }, null, 36), [
                [_vModelText, _ctx.runtimeData.sysConfig.initial_scale]
              ]),
              _createElementVNode("span", {
                style: _normalizeStyle(`color: var(--color-font${_ctx.initialScaleShow / 0.05 > 50 ? '-r' : ''})`)
              }, _toDisplayString(_ctx.initialScaleShow), 5)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.isMobile())
        ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'border-top-left'] }),
            _createElementVNode("div", null, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_view_fs_adaptation')), 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_view_fs_adaptation_tip')), 1)
            ]),
            _createElementVNode("div", _hoisted_34, [
              _withDirectives(_createElementVNode("input", {
                style: _normalizeStyle(`background-size: ${_ctx.fsAdaptationShow / 50 * 100}% 100%;`),
                type: "range",
                min: "0",
                max: "50",
                step: "10",
                "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.runtimeData.sysConfig.fs_adaptation) = $event)),
                name: "fs_adaptation",
                onChange: _cache[23] || (_cache[23] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args))),
                onInput: _cache[24] || (_cache[24] = 
//@ts-ignore
(...args) => (_ctx.setFsAdaptationShow && _ctx.setFsAdaptationShow(...args)))
              }, null, 36), [
                [_vModelText, _ctx.runtimeData.sysConfig.fs_adaptation]
              ]),
              _createElementVNode("span", {
                style: _normalizeStyle(`color: var(--color-font${_ctx.fsAdaptationShow / 50 > 0.5 ? '-r' : ''})`)
              }, _toDisplayString(_ctx.fsAdaptationShow) + " px", 5)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.runtimeData.tags.isElectron)
        ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'angle-up'] }),
            _createElementVNode("div", null, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_view_always_top')), 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_view_always_top_tip')), 1)
            ]),
            _createElementVNode("label", _hoisted_36, [
              _withDirectives(_createElementVNode("input", {
                type: "checkbox",
                onChange: _cache[25] || (_cache[25] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args))),
                name: "opt_always_top",
                "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.runtimeData.sysConfig.opt_always_top) = $event))
              }, null, 544), [
                [_vModelCheckbox, _ctx.runtimeData.sysConfig.opt_always_top]
              ]),
              _cache[34] || (_cache[34] = _createElementVNode("div", null, [
                _createElementVNode("div")
              ], -1))
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_37, [
        _createVNode(_component_font_awesome_icon, { icon: ['fas', 'arrows-rotate'] }),
        _createElementVNode("div", null, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_view_dont_touch')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('option_view_dont_touch_tip')), 1)
        ]),
        _createElementVNode("label", _hoisted_38, [
          _withDirectives(_createElementVNode("input", {
            type: "checkbox",
            onChange: _cache[27] || (_cache[27] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args))),
            name: "opt_revolve",
            "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.runtimeData.sysConfig.opt_revolve) = $event))
          }, null, 544), [
            [_vModelCheckbox, _ctx.runtimeData.sysConfig.opt_revolve]
          ]),
          _cache[35] || (_cache[35] = _createElementVNode("div", null, [
            _createElementVNode("div")
          ], -1))
        ])
      ])
    ])
  ]))
}