import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "update-info" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", null, _toDisplayString(_ctx.updated ? _ctx.$t('update_history') : _ctx.$t('new_update')), 1),
    _createElementVNode("a", null, _toDisplayString(_ctx.version), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.user.avatar
      }, null, 8, _hoisted_3),
      _createElementVNode("a", {
        href: _ctx.user.url
      }, _toDisplayString(_ctx.user.name), 9, _hoisted_4),
      _createElementVNode("span", null, _toDisplayString(Intl.DateTimeFormat(_ctx.getTrueLang(), { year: 'numeric', month: 'short', day: 'numeric' })
                        .format(new Date(_ctx.date))), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("span", null, _toDisplayString(_ctx.info.title), 1),
      _createElementVNode("div", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.info.content, (str, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: 'changelog-' + index
          }, [
            _createElementVNode("span", null, _toDisplayString(str), 1)
          ]))
        }), 128))
      ])
    ])
  ]))
}