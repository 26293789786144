import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "sys-not-list" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { key: 1 }
const _hoisted_7 = ["src"]
const _hoisted_8 = ["onClick"]
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { key: 2 }
const _hoisted_11 = { style: {"color":"var(--color-font-2)","word-wrap":"anywhere"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    id: "chat-pan",
    class: _normalizeClass('chat-pan sys-not-pan' + (_ctx.runtimeData.tags.openSideBar ? ' open': '') + (_ctx.runtimeData.sysConfig.opt_no_window ? ' withBar': ''))
  }, [
    _createElementVNode("div", null, [
      _createVNode(_component_font_awesome_icon, {
        onClick: _ctx.exit,
        icon: ['fas', 'angle-left']
      }, null, 8, ["onClick"]),
      _createElementVNode("span", null, _toDisplayString(_ctx.$t('sys_notice')), 1)
    ]),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.runtimeData.systemNoticesList, (notice, index) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: 'sysNot-' + index
        }, [
          (notice.request_type == 'friend')
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", null, [
                  _createElementVNode("img", {
                    src: 'https://q1.qlogo.cn/g?b=qq&s=0&nk=' + notice.user_id
                  }, null, 8, _hoisted_3),
                  _createElementVNode("div", null, [
                    _createElementVNode("span", null, _toDisplayString(notice.user_id) + " " + _toDisplayString(_ctx.$t('sys_notice_new_friend')), 1),
                    _createElementVNode("a", null, _toDisplayString(Intl.DateTimeFormat(_ctx.trueLang, { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' })
                                .format(new Date(notice.time * 1000))), 1),
                    _createElementVNode("a", null, _toDisplayString(_ctx.$t('sys_notice_message') + notice.comment), 1)
                  ])
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("button", {
                    onClick: ($event: any) => (_ctx.dealFriend(notice, false)),
                    class: "ss-button"
                  }, _toDisplayString(_ctx.$t('btn_reject')), 9, _hoisted_4),
                  _createElementVNode("button", {
                    onClick: ($event: any) => (_ctx.dealFriend(notice, true)),
                    class: "ss-button"
                  }, _toDisplayString(_ctx.$t('btn_accept')), 9, _hoisted_5)
                ])
              ]))
            : (notice.request_type == 'group')
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("div", null, [
                    _createElementVNode("img", {
                      src: 'https://p.qlogo.cn/gh/' + notice.group_id + '/' + notice.group_id + '/0'
                    }, null, 8, _hoisted_7),
                    _createElementVNode("div", null, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.getName(notice.user_id)) + " " + _toDisplayString(_ctx.$t('sys_notice_invite_group')) + " " + _toDisplayString(notice.group_id), 1),
                      _createElementVNode("a", null, _toDisplayString(Intl.DateTimeFormat(_ctx.trueLang, { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' })
                                .format(new Date(notice.time * 1000))), 1),
                      _createElementVNode("a", null, _toDisplayString(_ctx.$t('sys_notice_message') + notice.comment), 1)
                    ])
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("button", {
                      onClick: ($event: any) => (_ctx.dealGroupAdd(notice, false)),
                      class: "ss-button"
                    }, _toDisplayString(_ctx.$t('btn_reject')), 9, _hoisted_8),
                    _createElementVNode("button", {
                      onClick: ($event: any) => (_ctx.dealGroupAdd(notice, true)),
                      class: "ss-button"
                    }, _toDisplayString(_ctx.$t('btn_accept')), 9, _hoisted_9)
                  ])
                ]))
              : _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createElementVNode("div", null, [
                    _cache[0] || (_cache[0] = _createElementVNode("img", null, null, -1)),
                    _createElementVNode("div", null, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('sys_notice_unknow')), 1),
                      _createElementVNode("a", _hoisted_11, "request: " + _toDisplayString(notice.request_type) + "; sub: " + _toDisplayString(notice.sub_type), 1)
                    ])
                  ])
                ], 512)), [
                  [_vShow, _ctx.NODE_ENV == 'development']
                ])
        ], 64))
      }), 128))
    ])
  ], 2))
}