import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "ss-card face-pan" }
const _hoisted_2 = { icon: "fa-solid fa-face-laugh-squint" }
const _hoisted_3 = { class: "base-face" }
const _hoisted_4 = ["data-id", "onClick"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { icon: "fa-solid fa-heart" }
const _hoisted_7 = ["onClick", "src"]
const _hoisted_8 = { class: "ss-card" }
const _hoisted_9 = { icon: "fa-solid fa-store" }
const _hoisted_10 = { class: "store-face-list" }
const _hoisted_11 = ["src", "title", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_BcTab = _resolveComponent("BcTab")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BcTab, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.baseFaceMax, (num) => {
              return _withDirectives((_openBlock(), _createElementBlock("div", {
                "data-id": num,
                key: 'base-face-' + num,
                onClick: ($event: any) => (_ctx.addBaseFace(num))
              }, [
                _createElementVNode("img", {
                  loading: "lazy",
                  src: (_ctx.getFace(num) as any)
                }, null, 8, _hoisted_5)
              ], 8, _hoisted_4)), [
                [_vShow, _ctx.getFace(num) != false]
              ])
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", {
            class: "face-stickers",
            onScroll: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.stickersScroll && _ctx.stickersScroll(...args)))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.runtimeData.stickerCache, (url, index) => {
              return _withDirectives((_openBlock(), _createElementBlock("img", {
                loading: "lazy",
                onClick: ($event: any) => (_ctx.addImgFace(url)),
                key: 'stickers-' + index,
                src: url
              }, null, 8, _hoisted_7)), [
                [_vShow, url != 'end']
              ])
            }), 128)),
            _withDirectives(_createElementVNode("div", _hoisted_8, [
              _createVNode(_component_font_awesome_icon, { icon: ['fas', 'face-dizzy'] }),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('chat_face_pan_none').split('|')[0]), 1)
            ], 512), [
              [_vShow, _ctx.runtimeData.stickerCache && _ctx.runtimeData.stickerCache.length <= 0]
            ])
          ], 32)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.storeFace, (face) => {
              return (_openBlock(), _createElementBlock("div", {
                key: face.emoji_id
              }, [
                _createVNode(_component_font_awesome_icon, {
                  onClick: ($event: any) => (_ctx.removeMface(face)),
                  icon: ['fas', 'xmark']
                }, null, 8, ["onClick"]),
                _createElementVNode("img", {
                  loading: "lazy",
                  src: face.url,
                  title: face.summary,
                  onClick: ($event: any) => (_ctx.addMface(face))
                }, null, 8, _hoisted_11)
              ]))
            }), 128))
          ])
        ])
      ]),
      _: 1
    })
  ]))
}