import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "friend-view" }
const _hoisted_2 = { class: "base only" }
const _hoisted_3 = { class: "small" }
const _hoisted_4 = {
  icon: "fa-solid fa-thumbtack",
  id: "top"
}
const _hoisted_5 = {
  icon: "fa-solid fa-grip-lines",
  id: "canceltop"
}
const _hoisted_6 = {
  icon: "fa-solid fa-trash-can",
  id: "remove"
}
const _hoisted_7 = {
  icon: "fa-solid fa-check-to-slot",
  id: "readed"
}
const _hoisted_8 = {
  icon: "fa-solid fa-volume-high",
  id: "notice_open"
}
const _hoisted_9 = {
  icon: "fa-solid fa-volume-xmark",
  id: "notice_close"
}
const _hoisted_10 = { class: "ss-card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_BcMenu = _resolveComponent("BcMenu")!
  const _component_FriendBody = _resolveComponent("FriendBody")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass('friend-list' + (_ctx.runtimeData.tags.openSideBar ? ' open' : '')),
      id: "message-list"
    }, [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('message_title')), 1),
          _cache[1] || (_cache[1] = _createElementVNode("div", { style: {"flex":"1"} }, null, -1)),
          _createVNode(_component_font_awesome_icon, {
            onClick: _ctx.cleanList,
            icon: ['fas', 'trash-can']
          }, null, 8, ["onClick"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _withDirectives(_createElementVNode("span", null, _toDisplayString(_ctx.$t('message_title')), 513), [
            [_vShow, _ctx.runtimeData.tags.openSideBar]
          ]),
          _createElementVNode("div", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openLeftBar && _ctx.openLeftBar(...args)))
          }, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'bars-staggered'] })
          ])
        ])
      ]),
      _createVNode(_component_BcMenu, {
        data: _ctx.listMenu,
        onClose: _ctx.listMenuClose,
        name: "messages-menu"
      }, {
        default: _withCtx(() => [
          _createElementVNode("ul", null, [
            _createElementVNode("li", _hoisted_4, _toDisplayString(_ctx.$t('list_menu_top')), 1),
            _createElementVNode("li", _hoisted_5, _toDisplayString(_ctx.$t('list_menu_canceltop')), 1),
            _createElementVNode("li", _hoisted_6, _toDisplayString(_ctx.$t('list_menu_remove')), 1),
            _createElementVNode("li", _hoisted_7, _toDisplayString(_ctx.$t('list_menu_readed')), 1),
            _createElementVNode("li", _hoisted_8, _toDisplayString(_ctx.$t('list_menu_notice')), 1),
            _createElementVNode("li", _hoisted_9, _toDisplayString(_ctx.$t('list_menu_notice_close')), 1)
          ])
        ]),
        _: 1
      }, 8, ["data", "onClose"]),
      _createElementVNode("div", {
        id: "message-list-body",
        class: _normalizeClass((_ctx.runtimeData.tags.openSideBar ? 'open' : '')),
        style: {"overflow-y":"scroll"}
      }, [
        (_ctx.runtimeData.systemNoticesList && Object.keys(_ctx.runtimeData.systemNoticesList).length > 0)
          ? (_openBlock(), _createBlock(_component_FriendBody, {
              key: "inMessage--10000",
              select: _ctx.chat.show.id === -10000,
              data: { user_id: -10000, always_top: true, nickname: _ctx.$t('list_system_notice'), remark: _ctx.$t('list_system_notice') },
              onClick: _ctx.systemNoticeClick
            }, null, 8, ["select", "data", "onClick"]))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.runtimeData.onMsgList, (item) => {
          return (_openBlock(), _createBlock(_component_FriendBody, {
            key: 'inMessage-' + item.user_id ? item.user_id : item.group_id,
            select: _ctx.chat.show.id === item.user_id || (_ctx.chat.show.id === item.group_id && _ctx.chat.group_name != ''),
            menu: _ctx.menu.select && _ctx.menu.select == item,
            data: item,
            from: "message",
            onContextmenu: _withModifiers(($event: any) => (_ctx.listMenuShow($event, item)), ["prevent"]),
            onClick: ($event: any) => (_ctx.userClick(item)),
            onTouchstart: ($event: any) => (_ctx.showMenuStart($event, item)),
            onTouchend: _ctx.showMenuEnd
          }, null, 8, ["select", "menu", "data", "onContextmenu", "onClick", "onTouchstart", "onTouchend"]))
        }), 128))
      ], 2)
    ], 2),
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass('friend-list-space' + (_ctx.runtimeData.tags.openSideBar ? ' open' : ''))
    }, [
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_font_awesome_icon, { icon: ['fas', 'inbox'] }),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('chat_space')), 1)
      ])
    ], 2), [
      [_vShow, !_ctx.loginInfo.status || _ctx.runtimeData.chatInfo.show.id == 0]
    ])
  ]))
}